import React from "react";
import { Container, Title, Subtitle, Icon, Wrapper, FAQContainer, WppIcon } from './styles';

import instagramSvg from '../../assets/images/instagram_icon-2.svg';
import QuestionCard from "../../components/QuestionCard";
import PlansSection from "../../components/PlansSection";

function Plans() {
  return (
    <Container>
      <Wrapper>
        <Title>Confira Nossos Planos Dedicados em Promover Saúde de Cães e Gatos</Title>
        <Subtitle>Os Planos de Saúde Protetor Rede Vida Animal promovem preventivos em todos os níveis de controle além de acompanhamento médico veterinário periódico. Confira abaixo.</Subtitle>
      </Wrapper>
      <PlansSection backgroundColor="white" />
      <FAQContainer>
        <Title>Alguma dúvida sobre os nossos serviços?</Title>
        <Subtitle>Confira nosso FAQ:</Subtitle>
        <QuestionCard question="Por que adquirir um plano de saúde veterinário preventivo?" answer="A prevenção é muito mais econômica para o tutor quando comparada às medidas emergenciais ou tratativas. Muitas doenças, inclusive zoonoses, podem ser evitadas. Outras enfermidades pré-existentes podem ser reveladas e precocemente tratadas. É desta forma que promovemos saúde, longevidade e melhor qualidade de vida." />
        <QuestionCard question=" O Plano Protetor possui carência?" answer="Não tem carência. Após fazer a adesão e assinar nosso contrato, você receberá o planejamento da execução dos serviços preparado com atenção e destreza por um médico veterinário, com data e horário em acordo com a sua disponibilidade, para iniciarmos os serviços o mais rápido possível." />
        <QuestionCard question="O que é a Carteira de Saúde Digital e para que serve?" answer="O Rede Vida Animal conta com um sistema para facilitar toda a gestão, agendamento, consultas e prontuários em um único lugar - de forma prática, rápida e compatível com smartphones. Permitindo que você acesse o histórico do seu animal de qualquer lugar do mundo e em qualquer ocasião em que seja necessário." />
        <QuestionCard question="Consultas Médico Veterinário recorrentes: como funciona e para que serve?" answer="O Rede Vida Animal trabalha com prevenção. Acreditamos ser primordial aumentar a frequência do seu animal junto ao médico veterinário. Para tanto, sugerimos consultas com intervalos trimestrais. Desta forma gerando uma troca positiva na relação médico/paciente e trazendo confiança consequentemente, além de boa saúde e antecipando problemas através do acompanhamento veterinário periódico." />
        <QuestionCard question="Chip: serve para localizar animais? Está incluso no plano?" answer="O Chip é identificador, porém não localiza o animal. Ressaltamos que o Chip é exigência para viagens internacionais dos pets. Os clientes dos Planos Protetor podem adicionar o Chip no contrato por um valor especial – e que por sua vez pode ser diluído na parcela mensal" />
        <QuestionCard question="O que são “Lembretes” e para que servem? Quais são os lembretes?" answer="Os lembretes são mensagens que o Rede Vida Animal envia para seus clientes e servem para que os tutores não esqueçam dos serviços preventivos a realizar junto ao pet. Na sua correriado dia a dia, pode ficar tranquilo que nós lembramos e cuidamos do seu melhor amigo" />
        <QuestionCard question="Seguro Pet: como funciona e para que serve se adquirir o Seguro?" answer="Para ocasiões nas quais emergências e urgências surgem e nos pegam desprevenidos, o seguro ajuda a minimizar e ou amortizar os gastos extras. E em caso de morte do animal, o Seguro conta com cobertura de cremação, promovendo um fechamento de ciclo de vida com dignidade para o seu melhor amigo. " />
        <QuestionCard question="Atestado de Saúde nacional: o que é e quando preciso dele?" answer="Na hora de viajar com seu pet, será exigido pelas autoridades que a vacina contra raiva - que é obrigatória – seja realizada previamente pelo médico veterinário dentro do prazo de validade, além do atestado de saúde padrão - que também precisa ser assinado pelo médico veterinário. Todos os nossos Planos Protetor contemplam essas exigências, facilitando seu deslocamento em território nacional." />
        <QuestionCard question="O que são os aditivos? Estes estão inclusos no plano?" answer="Os aditivos são serviços extras dos quais você pode optar por acrescentar no momento da adesão do seu plano. Estes adicionais servem para aumentar a qualidade e monitoramento da boa saúde, minimizando riscos de mazelas para o pet e toda família." />
        <QuestionCard question=" Clube de Vantagens: O que é? Para que serve?" answer="Os clientes do Plano Protetor são beneficiados com Clube de Vantagens, que são descontos e brindes ofertados pelo Rede Vida Animal e nossos parceiros." />
      </FAQContainer>
      <Wrapper>
        <Subtitle>Ou chame-nos no WhatApp, responderemos o mais breve possível</Subtitle>
        <a rel="noreferrer" target="_blank" href="https://api.whatsapp.com/send?phone=5522997935322&text=Entrei%20no%20site%20Rede%20Vida%20Animal%20e%20gostaria%20de%20saber%20mais%20informa%C3%A7%C3%B5es.">
          <WppIcon />
        </a>
        <Subtitle>E para conhecer melhor o Rede Vida Animal siga-nos no instagram </Subtitle>
        <a href="https://www.instagram.com/redevidaanimal/">
          <Icon src={instagramSvg} />  
        </a>
        <Subtitle>@redevidananimal</Subtitle>
      </Wrapper>
    </Container>
  );
}

export default Plans;