import React, { useState } from "react";
import {Wrapper,
        ToggleWrapper,
        Title,
        CardWrapper
} from "./styles"

import HealthPlanCardAddsItem from "../HealthPlanCardAddsItem";

function HealthPlanCardAdds(props){

    const [toggle, setToggle] = useState(0);

    function toggleAdds(){
        setToggle(!toggle)
    }

    return(
        <Wrapper>
            <ToggleWrapper onClick={toggleAdds}>
                <Title>Adicionais</Title>
            </ToggleWrapper> 
            <CardWrapper toggle={toggle}>
            {
                props.adds?.map((item) => {
                    return(
                        <HealthPlanCardAddsItem key={item.id} name={item.nome} addsList={props.addsList} quantity={item.quantidade} price={item.valor} valueTotal={props.valueTotal} valueParcels={props.valueParcels} />
                    )
                })
            }
                
            </CardWrapper>
        </Wrapper>
    )
}

export default HealthPlanCardAdds