import React from "react";

import { 
    CardContainer,
    ScheduleBtn,
    PartnerInfo,
    Wrapper } from "./styles";

import parceiro_icon from "../../assets/images/parceiro.png"
import { useNavigate } from 'react-router-dom';



function PartnerCard(props){
    const navigate = useNavigate();
    
    return(
        <CardContainer>
            <Wrapper>
                <img src={props.src? props.src : parceiro_icon} alt="" />
                <PartnerInfo>
                    <p>{props.name}</p>
                    <p>{props.city}</p>
                    <p>Especialidades: {props.specialties}</p>
                    <p>Serviços: {props.benefits}</p>
                </PartnerInfo>      
            </Wrapper>
            <ScheduleBtn onClick={() => navigate(`/parceiros/${props.id}`)}>Ver mais</ScheduleBtn>
        </CardContainer>
    )
}

export default PartnerCard