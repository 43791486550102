import styled from "styled-components"

const Colors = {
    firstContainer: '#85DAB6',
    secondContainer: '#168C5A',
    firstContainerText: '#1C1C1C',
    secondContainerText: '#D2F1DC'
}

export const Wrapper = styled.div``

export const FirstContainer = styled.div`
    background-color: ${Colors.firstContainer};
    padding: 1rem;

    color: ${Colors.firstContainerText};

    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 2.5rem;

    @media screen and (max-width: 834px){
        padding: 5rem 10px;
    }
`
export const SecondContainer = styled.div`
    background-color: ${Colors.secondContainer};
    padding: 2rem 5rem;
    gap: 10px;

    color: ${Colors.secondContainerText};

    display: flex;
    flex-direction: column;

    @media screen and (max-width: 834px){
        padding: 2.5rem 10px;
    }
`

export const FirstContainerText = styled.p`
    font-family: 'Noto Sans', sans-serif;
    font-size: 16px;

    width: min(30rem, 100%);
    text-align: justify;

    
`

export const TextTitle = styled.span`
    font-family: 'Noto Sans', sans-serif;
    color: #168C5A;
    font-size: 18px;
    font-weight: bold;
    display: block;
`

export const ContactsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;

    gap: 0.5rem;
`

export const ContactsIcon = styled.img`
    width: 50px;
    height: 50px;
`

export const ContactsIconTextContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    gap: 1rem;
`

export const LogoFooter = styled.img`
    width: 100%;
    max-width: 300px;
`

export const SecondContainerText = styled.p`
    font-family: 'Noto Sans', sans-serif;
    font-size: 20px;
    text-align: center;
`
export const SecondContainerText2 = styled.p`
    font-family: 'Noto Sans', sans-serif;
    font-size: 12px;
    text-align: center;
`

export const CopyrightWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
`
export const CopyrightIcon = styled.img`
    width: 66px;
`