import styled from "styled-components";

export const Card = styled.div`
    font-family: 'Noto Sans', sans-serif;

    border: 0.5px solid black;
    border-radius: 10px; 
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 100%;
    max-width: 332px;
    min-width: 258px;
    margin: 0 auto;
    padding-bottom: 30px;
`

export const CardTitle = styled.div`
    background-color: ${props => props.isGreen ? "#168C5A" : "#F28E1F"};
    color: #FAFAFA;

    font-size: 1.5rem;
    text-align: center;

    padding: 20px 0;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
`

export const CardInfo = styled.div`
    color: #1C1C1C;
    font-weight: 400;

    display: flex;
    flex-direction: column;
    gap: 15px;

    padding: 30px 15px;
`

export const CardInfoTitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 15px;
`

export const LinkWrapper = styled.a`
    text-align: center;

`

export const CardButton = styled.button`
    background-color: ${props => props.isGreen ? "#168C5A" : "#F28E1F"};
    color: #FAFAFA;
    
    font-family: 'Poppins', sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;

    cursor: pointer;

    width: 80%;
    margin: 0 auto;
    padding: 3px 20px;
    border-radius: 8px;
    border: none;
`

export const CardPriceBtnWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 15px;
`

export const CardPriceWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px 15px;
`

export const CardPrice = styled.p`
    font-size: 2rem;
    font-weight: 500;
    color: #1C1C1C;
`

export const CardPrice2 = styled.p`
    margin-top: 0.5rem;
    margin-left: 0.25rem;
    font-size: 1.25rem;
    font-weight: 400;
    color: #848484;
`