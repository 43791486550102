import React, {useState} from "react";

import {
    ItemWrapper,
    InfoWrapper,
    BtnsWrapper,
    Name,
    Quantity,
    Button,
    Sup
} from "./styles"

import addIcon from "../../assets/images/add.svg"
import removeIcon from "../../assets/images/remove.svg"

function HealthPlanCardAddsItem(props){

    const [addButton, setAddButton] = useState(addIcon)

    function addItem(){
        props.addsList(props.name)
        if(addButton === addIcon){
            setAddButton(removeIcon) 
            props.valueTotal[1](props.valueTotal[0] + (props.price * 1))
            props.valueParcels[1](props.valueParcels[0] + (props.price * 1))
        }
        else {    
            setAddButton(addIcon)
            props.valueTotal[1](props.valueTotal[0] - (props.price * 1))
            props.valueParcels[1](props.valueParcels[0] - (props.price * 1))
        }
    }

    return(
        <ItemWrapper>
            <InfoWrapper>
                <Name>{props.name} </Name>
            </InfoWrapper>
            <BtnsWrapper onClick={addItem}>
                <Button src={addButton} />
            </BtnsWrapper>
        </ItemWrapper>
    )
}

export default HealthPlanCardAddsItem