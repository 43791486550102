import styled from "styled-components";

export const Wrapper = styled.div`
    font-family: 'Noto Sans', sans-serif;
    font-size: 1.25rem;
    color: #1C1C1C;
    font-weight: 400;
`

export const ToggleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 5px;
`

export const Title = styled.h1`

`

export const CardWrapper = styled.div`
    background-color: #E2E2E2;
    border: 0.5px solid black;
    border-radius: 4px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 5px 10px;
    transition: all 0.5s;

    width: 100%;
    max-height: 238px;

    overflow-y: scroll;

    &::-webkit-scrollbar{
        width: 4px;
    }

    &::-webkit-scrollbar-thumb{
        background: #B4B4B4;
        border-radius: 5px;
        border-left: 1px solid #E2E2E2;
        border-right: 1px solid #E2E2E2;
    }
`