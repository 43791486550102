import React from "react";

import {Wrapper,
        FirstContainer,
        SecondContainer,
        TextTitle, 
        FirstContainerText,
        ContactsContainer,
        ContactsIconTextContainer,
        ContactsIcon,
        LogoFooter,   
        SecondContainerText,
        CopyrightWrapper,
        CopyrightIcon,
        SecondContainerText2,
    } from './styles';

import whatsapp from '../../assets/images/whatsapp_icon.svg'
import instagram from '../../assets/images/instagram_icon.svg'
import logo_footer from '../../assets/images/logo_footer.svg'
import in_junior_logo from '../../assets/images/in_junior_logo.svg'
import email from '../../assets/images/email.svg'

function Footer(){
    return(
        <Wrapper>
            <FirstContainer>
                <FirstContainerText>
                    <TextTitle>Missão:</TextTitle> Tornar o atendimento médico veterinário uma rotina da família, atendendo o cliente e o paciente com atenção, promovendo uma experiência segura e gerando um valor de confiança do cliente, paciente e médico veterinário, que por sua vez mantém o compromisso de acompanhar a vida do seu paciente proporcionando segurança e transparência nos cuidados com o animal e como consequência uma vida longa, saudável e feliz com memórias positivas para toda a família
                    <br/> <br/>
                    <TextTitle>Valores:</TextTitle> Nosso maior valor é a saúde do seu pet. Todos ganham e colecionam memórias de felicidade. De geração a geração, fala o popular; previnir é melhor que remediar
                </FirstContainerText>
                <ContactsContainer>
                <ContactsIconTextContainer>
                    <ContactsIcon src={email} />
                    <FirstContainerText>
                        <span>redevida<wbr />animal<wbr />@redevida<wbr />animal<wbr />.com.br</span>
                    </FirstContainerText>
                </ContactsIconTextContainer>
                    <ContactsIconTextContainer>
                    <a rel="noreferrer" target="_blank" href="https://api.whatsapp.com/send?phone=5522997935322&text=Entrei%20no%20site%20Rede%20Vida%20Animal%20e%20gostaria%20de%20saber%20mais%20informa%C3%A7%C3%B5es.">
                        <ContactsIcon src={whatsapp} />
                    </a>
                        <FirstContainerText>
                            (22) 99793<wbr />-5322
                        </FirstContainerText>
                    </ContactsIconTextContainer>
                    <ContactsIconTextContainer>
                        <a  href="https://www.instagram.com/redevidaanimal/">
                            <ContactsIcon instagram={true} src={instagram} />
                        </a>
                        <FirstContainerText>
                            @redevida<wbr />animal
                        </FirstContainerText>
                    </ContactsIconTextContainer>
                </ContactsContainer>
                <LogoFooter src={logo_footer} alt="Rede Vida Animal" />
            </FirstContainer>
            <SecondContainer>
                <SecondContainerText>
                Razão social: Rede Vida Animal LTDA. CNPJ: 26.361.900/0001-58
                </SecondContainerText>
                <SecondContainerText>
                Sede: Rua Rui Barbosa, 360, Loja 1, Centro, Armação dos Búzios, RJ, 28950-855.
                </SecondContainerText>
                <SecondContainerText2>
                    Médico Veterináio Responsável: Dr. Rodrigo Fróes - CRMV-RJ: 8552
                </SecondContainerText2>
                <CopyrightWrapper>
                    <SecondContainerText>
                        Site construído por IN Junior
                    </SecondContainerText>
                    <a rel="noreferrer" target="_blank" href="https://injunior.com.br/">
                        <CopyrightIcon src={in_junior_logo} />
                    </a>
                </CopyrightWrapper>
            </SecondContainer>
        </Wrapper>
    )
}

export default Footer;