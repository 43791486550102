import React, {useState} from "react";

import SchedulingBanner from "../../components/SchedulingBanner";
import SchedulingInfo from "../../components/SchedulingInfo";
import SchedullingPopUp from "../../components/SchedullingPopUp";

function Scheduling(){

    const [isOpen, setIsOpen] = useState(false)
    const body = document.getElementsByTagName("body")[0];
    
    function togglePopUp(){
        if(!body.classList.contains("hidden")){
            body.classList.add("hidden")
        }
        else{
            body.classList.remove("hidden")
        }
        setIsOpen(!isOpen)
    }
 

    return(
        <>
            <SchedullingPopUp tel="https://api.whatsapp.com/send?phone=5522997935322&text=" isOpen={isOpen} toggle={togglePopUp} />
            <SchedulingBanner isOpen={isOpen} toggle={togglePopUp} />
            <SchedulingInfo />       
        </>
        
    )
}

export default Scheduling