import React, { useContext } from 'react';
import { Container,
         VetWrapper,
         VetContainer,
         DetailsContainer,
         HeaderContainer,
         TitleWrapper,
         Slogan,
         ScheduleWrapper,
         LocalWrapper,
         LocalTitleWrapper,
         Wrapper,
         Title,
         Image,
         VetImage,
         Label,
         Text,
         List,
         Li,
         Map,
         Icon,
         SocialMediaContainer,
         WppMediaIcon,
         InstaMediaIcon,
         Link,
         Highlight,
         Selector,
         SelectorOption,
         UnitVideo,
         BottomSection,
        } from './styles';
import SchedullingPopUp from '../../components/SchedullingPopUp';

import clockSvg from '../../assets/images/clock.svg';
import vetIconPng from '../../assets/images/vet_icon.png';
import catDogIcon from '../../assets/images/cat_dog_icon.png';
import { useParams } from 'react-router-dom';
import { GlobalContext } from '../../context/GlobalContext';
import { useEffect, useState } from 'react';

function SingleVet() {

  const { id } = useParams();
  const context = useContext(GlobalContext);

  useEffect(() => {
    context.handleSingleVet(id);
  }, [])

  const vet = context.vet;
  
  const [isOpen, setIsOpen] = useState(false)
  const body = document.getElementsByTagName("body")[0];
  
  function togglePopUp(){
    if(!body.classList.contains("hidden")){
      body.classList.add("hidden")
    }
    else{
      body.classList.remove("hidden")
    }
    setIsOpen(!isOpen)
  }
  
  const handleSelect = (event) => {
    event.preventDefault()
    setEndereco(event.target.value)
  }
  const [endereco, setEndereco] = useState("initial")

  return (
    <>
    <SchedullingPopUp tel={`${vet.whatsapp}&text=`} isOpen={isOpen} toggle={togglePopUp} name={vet.nome} />
    <Container>
      {Object.keys(vet).length !== 0 &&
        <>
          <VetContainer>
            <HeaderContainer>
              <Image src={vetIconPng} />
              <TitleWrapper>
                <Title>{vet.nome}</Title>
                <Slogan>{vet.descricao}</Slogan>
              </TitleWrapper>
              <Image src={catDogIcon} />
            </HeaderContainer>
            <VetWrapper>
              <DetailsContainer>
                <Wrapper><Label>Número CRMV-DF:</Label><Text>{vet.crmv.numero}-{vet.crmv.estado}</Text></Wrapper>
                <Wrapper><Label>Região de atendimento:</Label><Text>{vet.lugares_de_atendimento}</Text></Wrapper>
                <Label>Estabelecimentos de atendimento:</Label>
                <List>
                  {vet.unidades_de_atendimento &&
                    vet.unidades_de_atendimento.map(unidade => {
                      return (<Li>{unidade.nome} <Icon src={clockSvg} /></Li>)
                    })
                  }
                </List>
                <Wrapper><Label>Especialidades:</Label><Text>{vet.especialidades}</Text></Wrapper>
                <Wrapper><Label>Serviços Ofertados:</Label><Text>{vet.servicos_ofertados}</Text></Wrapper>
                { vet.atendimento_domiciliar && <Label>Este profissional atende em domicílio</Label>}
              </DetailsContainer>
              <VetImage src={vet.foto} />
            </VetWrapper>
            <ScheduleWrapper>
              <Label>
                <p>Agende a consulta, vacina, ou outros serviços médico veterinários para o seu pet<Label></Label>
                <Highlight onClick={togglePopUp}>clicando aqui</Highlight></p>
              </Label>
            </ScheduleWrapper>
          </VetContainer>
          <LocalWrapper>
              <LocalTitleWrapper>
                <Title>Local e horários de atendimento</Title>
                <SocialMediaContainer>
                  <Link rel="noreferrer" target="_blank" href={`${vet.whatsapp}`}><WppMediaIcon /></Link>
                  <Link href={`${vet.instagram}`} target="#"><InstaMediaIcon /></Link>
                </SocialMediaContainer>
              </LocalTitleWrapper>
              <Text>Selecione a unidade que gostaria de ver no mapa</Text>
              <Selector onChange={handleSelect}>
              {vet.unidades_de_atendimento &&
                vet.unidades_de_atendimento.map(unidade => {
                  return (<SelectorOption value={`${unidade.endereco.logradouro}, ${unidade.endereco.numero}, ${unidade.endereco.bairro}, ${unidade.endereco.cidade}`} >{unidade.nome}</SelectorOption>)
                  })
              }
                </Selector>
              {vet.unidades_de_atendimento[0] && <Text>Endereço: {endereco === "initial" ? `${vet.unidades_de_atendimento[0].endereco.logradouro}, ${vet.unidades_de_atendimento[0].endereco.numero}, ${vet.unidades_de_atendimento[0].endereco.bairro}, ${vet.unidades_de_atendimento[0].endereco.cidade}` : endereco}</Text>}
              <Text>Horários: {vet.horarios_de_atendimento}</Text>
              
              <BottomSection>
                  {vet.unidades_de_atendimento[0] && 
                    <Map
                    src={`https://www.google.com/maps?q=${endereco === "initial" ? `${vet.unidades_de_atendimento[0].endereco.logradouro}, ${vet.unidades_de_atendimento[0].endereco.numero}, ${vet.unidades_de_atendimento[0].endereco.bairro}, ${vet.unidades_de_atendimento[0].endereco.cidade}` : endereco}&output=embed`}
                    width="100%"
                    height="400"
                    allowfullscreen=""
                    loading="lazy" referrerpolicy="no-referrer-when-downgrade"
                    />
                  }
                  {vet.video && <UnitVideo src={`https://www.youtube.com/embed/${vet.video.split("=")[1]}`}/>}
              </BottomSection>

            </LocalWrapper>
        </>}
    </Container>
    </>

  )
}

export default SingleVet;