import React from "react";
import { Wrapper, BannerContainer, BannerTitle, BannerList, ListMarker, BannerPointer } from "./styles";

import doctor_img from "../../assets/images/schedule_doctor.svg"
import arrow from "../../assets/images/arrow_down.svg"
import { Link } from 'react-router-dom';

function SchedulingBanner(props){
    return(
        <Wrapper>
            <BannerContainer>
                <BannerTitle>
                    <h1>Como funciona o Agendamento Online?</h1>
                    <img src={doctor_img} alt={'ilustração veterinária'} />
                </BannerTitle>
                <BannerList>
                    <ol>
                        <h1>{"Garanta o serviço médico veterinário desejado no momento certo. Além disso, você  não precisa se preocupar com filas ou esperar por horas para ser atendido. Faça sua reserva seguindo nosso passo a passo."}</h1>
                        <li><ListMarker>1</ListMarker><p>Confira nossos médicos veterinários <Link to='/medicos-veterinarios'> <span>clicando aqui.</span></Link></p></li>
                        <li><ListMarker>2</ListMarker><p>Entre em contato com a gente informando data e período escolhido, você pode fazer isso <span onClick={props.toggle}>clicando aqui.</span></p></li>
                        <li><ListMarker>3</ListMarker><p>Pronto, agora será encaminhado ao local de atendimento com data e hora de sua preferência. Fácil, prático e conveniente para você.</p></li>
                    </ol>
                </BannerList> 
            </BannerContainer>
            <BannerPointer>
                <p>Confira algumas informações importantes</p>
                <img src={arrow} alt={'seta para baixo'} />
            </BannerPointer>
    </Wrapper>
    )
}

export default SchedulingBanner