import React from "react";

import { Container, QuestionContainer, AnswerContainer, QuestionText, AnswerText, ArrowIcon } from './styles';

import arrowSvg from '../../assets/images/menu_arrow.svg';
import { useState } from "react";

function QuestionCard({ question, answer }) {
  const [isOpen, setOpen] = useState(false)

  return (
    <Container onClick={() => setOpen(!isOpen)}>
      <QuestionContainer>
        <ArrowIcon src={arrowSvg} isOpen={isOpen} />
        <QuestionText>{question}</QuestionText>
      </QuestionContainer>
      {
        isOpen && (
          <AnswerContainer>
            <AnswerText>{answer}</AnswerText>
          </AnswerContainer>
        )
      }
    </Container>
  );
}

export default QuestionCard;