import GlobalStyle from './styles/global';
import { ThemeProvider } from 'styled-components';
import { AppRoutes } from './routes/Routes';
import theme from './styles/themes/default'
import Footer from './components/Footer';

import Header from './components/Header'
import { BrowserRouter  } from 'react-router-dom';
import { GlobalProvider } from './context/GlobalContext';



function App() {
  return (
    <div className="App">
          <BrowserRouter >
          <GlobalProvider>
              <ThemeProvider theme={theme}>
                <GlobalStyle />
                <Header />
                <AppRoutes />
                <Footer />
              </ThemeProvider>
            </GlobalProvider>
          </BrowserRouter >
    </div>
  );
}

export default App;
