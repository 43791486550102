import { createContext } from "react";
import { useContext } from "./hooks/useContext";

const GlobalContext = createContext()

function GlobalProvider({ children }) {
  const {
    handleCities,
    cities,
    handleVets,
    vets,
    handleUnits,
    units,
    handleSingleVet,
    vet,
    handleSingleUnit,
    unit
  } = useContext()

  return (
    <GlobalContext.Provider
      value={
        {
          handleCities,
          cities,
          handleVets,
          vets,
          handleUnits,
          units,
          handleSingleVet,
          vet,
          handleSingleUnit,
          unit
        }
      }>
      {children}
    </GlobalContext.Provider>
  )
}

export { GlobalContext, GlobalProvider }
