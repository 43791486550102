import React, { useContext } from 'react';

import { ReactSearchAutocomplete } from 'react-search-autocomplete';

import { Container, MainContainer, Wrapper, Title, Image, ResultsContainer, ResultsWrapper, VetDetail, Text, Button} from './styles';

import hospital from '../../assets/images/hospital.svg'
import { GlobalContext } from '../../context/GlobalContext';
import { data } from '../../services/data';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Units() {
  const context = useContext(GlobalContext);

  useEffect(() => {
    context.handleCities('units');
  }, [])

  let cities = context.cities;
  cities = cities.filter((elem, index, self) => self.findIndex(
    (t) => { return (t.cidade === elem.cidade) }) === index)

  const handleOnSelect = (item) => {
    context.handleUnits(item);
  }

  const handleOnClear = () => {
    context.handleUnits();
  }

  const units = context.units;

  const navigate = useNavigate();

  return (
    <Container>
      <MainContainer>
        <Wrapper>
          <Title color='#000'>Encontre unidades, estabelecimentos, clinicas, hospitais e laboratórios veterinários próximos de você.</Title>
          <ReactSearchAutocomplete
            items={cities}
            fuseOptions={
              { keys: ["cidade"] }
            }
            resultStringKeyName="cidade"
            placeholder='Procure aqui a cidade'
            autoFocus
            onSelect={handleOnSelect}
            onClear={handleOnClear}
            showNoResultsText="Sem resultados"
            styling={{

              borderRadius: "8px",
              backgroundColor: "#E6E6E6",
              color: "#555",
              fontFamily: "Poppins",
              hoverBackgroundColor: "#E0E0E0",
              placeholderColor: "black"
            }}
          />
        </Wrapper>
        {units.length === 0 && <Image src={hospital} />}
      </MainContainer>
      {
        units.length !== 0 &&
        <ResultsContainer>
          <Title color='#168C5A'>Veterinários em cidade: </Title>
          <ResultsWrapper>
            {
              units.map(unit => {
                return (
                  <VetDetail>
                    <Text>{unit.nome} - {unit.endereco.bairro}</Text>
                    <Button onClick={() => navigate(`/unidades/${unit.id}`)}>VER DETALHES</Button>
                  </VetDetail>
                )
              })
            }

          </ResultsWrapper>
        </ResultsContainer>
      }
    </Container>
  )
}

export default Units;