import styled from 'styled-components';


export const Container = styled.div`
  position: absolute;

  top: 80px;
  right: 0;

  background-color: ${({ theme }) => theme.colors.principal};

  border-radius: 0 0 0 5px;
  z-index: 10;
`;

export const CloseWrapper = styled.div`
  display: flex;
  justify-content: end;
  padding: .5rem;

`;

export const CloseIcon = styled.img`
  cursor: pointer;
`;

export const List = styled.ul`
`;

export const Item = styled.li`
  background-color: ${({ theme }) => theme.colors.principal};

  text-align: center;
  padding: 1.5rem;
  font-size: 1.5rem;
  font-family: ${({ theme }) => theme.fonts.texto_funcional.font_family};
  font-weight: ${({ theme }) => theme.fonts.texto_funcional.font_weight};
  color: ${({ theme }) => theme.colors.respiro};

  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.menu_hover};
  }
`;

export const Icon = styled.img`
  padding-left: 0.5rem;
`;

export const BtnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  height: 7rem;
  padding-bottom: 1rem;
`;