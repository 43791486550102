import styled from "styled-components";

export const PopUpContainer = styled.div`
    background-color: #FAFAFA;

    font-family: 'Noto Sans', sans-serif;
    font-size: 1.25rem;
    color: #1C1C1C;

    display: ${props => props.isOpen? "flex" : "none"};
    flex-direction: column;
    
    box-sizing: border-box;

    position: absolute;

    z-index: 4;

    scrollbar-width: none;
    -ms-overflow-style: none;

    gap: 20px;
    padding: 20px;
    padding-top: 45px;
    max-width: 500px;
    max-height: 90vh;
    margin: 10px;
    border-radius: 10px;

    overflow: scroll;

    img{
        position: absolute;
        left: 20px;
        top: 20px;

        cursor: pointer;

        width: 20px;
        height: 20px;
    }

    h1{
        font-size: 24px;
        color: #168C5A;
        font-weight: 500;
        letter-spacing: 0.04em;
    }

    form{
        display: flex;
        flex-direction: column;

        width: 100%;
        gap: 10px;
    }

    input{
        background-color: #E6E6E6;

        width: 100%;

        box-sizing: border-box;

        padding: 5px;
        border: none;
        border-radius: 8px;


    }

    &::-webkit-scrollbar {
        display: none;
    }  
`

export const PersonalData = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;
    gap: 10px;

    input{
        width: 100%;
    }

`

export const DatesData = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;

    gap: 10px;

    h2{
        width: 100%;
    }

    input{
        width: 70%;
        margin: auto;
    }
`
export const DateInput = styled.div`
    display: flex;
    justify-content: space-between;

    input{
        width: 30%;
        margin: 0;
    }

    select{
        background-color: #E6E6E6;
        color: #000;

        text-align: center;
        
        width: 60%;

        border: none;
        border-radius: 8px;
    }
`

export const FormButton = styled.input`
    background-color: #168C5A !important;
    color: #FAFAFA;

    font-family: 'Poppins', sans-serif;
    font-size: 1.5rem;
    font-weight: 600;

    cursor: pointer;

    width: 122px !important;
    padding: 3px 20px;
    margin: auto;
`

export const GreyBackground = styled.div`
    background-color: rgb(161, 161, 161, 0.5);

    width: 100vw;
    height: 100vh;


    display: ${props => props.isOpen? "flex" : "none"};
    align-items: center;
    justify-content: center;

    position: fixed;
    top: 0 !important;
    left: 0 !important;
    z-index: 3;
`