const theme = {
  colors: {
    principal: '#168C5A',
    secundaria: '#85DAB6',
    call_to_action: '#F28E1F',
    green_btn: '#1CAE70',
    complementar: '#10A48E',
    texto_verde: '#10A339',
    texto_preto: '#1C1C1C',
    respiro: '#FAFAFA',
    menu_hover: '#1DAF71'
  },
  fonts: {
    titulo: {
      font_family: "'Raleway', sans-serif",
      font_weight: "700"
    },
    subtitulo: {
      font_family: "'Noto Sans', sans-serif",
      font_weight: "500"
    },
    texto_corrido: {
      font_family: "'Noto Sans', sans-serif",
      font_weight: "400"
    },
    texto_funcional: {
      font_family: "'Poppins', sans-serif",
      font_weight: "600"
    }
  }
};

export default theme;