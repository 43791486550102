import React from 'react';
import {
  Container,
  DogWrapper,
  DogImg,
  CloudSvg,
  Wrapper,
  BorderDesignRightSvg,
  BorderDesignLeftSvg,
  DetailsWrapper,
  PlusOutlineLeftSvg,
  PlusOutlineRightSvg,
  HealthPlanSvg,
  DetailsRightSvg,
  DetailsLeftSvg,
  BorderLeftWrapper,
  CloudLeftWrapper,
  MainContentContainer,
  InfoContainer,
  LogoContainer,
  TitleBackgroundSvg,
  Title,
  Subtitle,
  Text,
  BigText,
  DownArrowSvg
} from './styles';

import dog from '../../assets/images/banner/dog.png';
import cloud from '../../assets/images/banner/cloud.svg';
import borderDesignRight from '../../assets/images/banner/border_design_right.svg';
import borderDesignLeft from '../../assets/images/banner/border_design_left.svg';
import healthPlan from '../../assets/images/banner/health_plan.svg';
import details from '../../assets/images/banner/details.svg';
import plus from '../../assets/images/banner/plus_outline.svg';
import titleBackground from '../../assets/images/banner/title_background.svg'
import downArrow from '../../assets/images/banner/down_arrow.svg';

function Banner() {
  return (
    <Container>
      <Wrapper>
        <BorderLeftWrapper>
          <DetailsLeftSvg src={details} />
          <CloudLeftWrapper>
            <BorderDesignLeftSvg src={borderDesignLeft} />
            <PlusOutlineLeftSvg src={plus} />
          </CloudLeftWrapper>
        </BorderLeftWrapper>
        <InfoContainer>
          <LogoContainer>
            <TitleBackgroundSvg src={titleBackground} />
            <Title>REDE VIDA ANIMAL</Title>
          </LogoContainer>
          <Subtitle>Pioneiro em Saúde Preventiva</Subtitle>
          <Text>Somos uma plataforma que conecta você a médicos veterinários, unidades de atendimento e parceiros do mercado Pet. Destacamos o Plano Protetor que promovem boa saúde e longevidade do seu melhor amigo.</Text>
          <BigText>Desejo um plano</BigText>
          <DownArrowSvg src={downArrow} />
        </InfoContainer>
        <MainContentContainer>
          <DogWrapper>
            <DogImg src={dog} />
            <CloudSvg src={cloud} />
          </DogWrapper>
          <DetailsWrapper>
            <PlusOutlineRightSvg src={plus} />
            <HealthPlanSvg src={healthPlan} />
            <DetailsRightSvg src={details} />
          </DetailsWrapper>
        </MainContentContainer>
        <BorderDesignRightSvg src={borderDesignRight} />
      </Wrapper>
    </Container >
  );
}

export default Banner;  