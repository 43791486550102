import styled from "styled-components";

export const TitleWrapper = styled.div`
    background-color: #168C5A;
    color: #FAFAFA;
    justify-content: space-evenly;
    text-align: center;
    align-items: center;
    padding: 10px 20px;
    display: flex;
    
    @media screen and (max-width: 824px) {
        margin:1rem;
        border-radius: 10px;
    }
 `

 export const Title = styled.h1`
    font-family: 'Raleway', sans-serif;
    font-size: 3rem;
    font-weight: 700;

    @media screen and (max-width: 320px) {
        font-size: 1.5rem;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
    }
 `

export const CardsWrapper = styled.div`
    display: grid;
    grid-template-columns:${props => (props.size <= 1)? "auto" : (props.size <= 5)? "auto auto" : "auto auto auto"};
    grid-template-rows: repeat(1, auto);
    grid-column-gap: 10px;
    grid-row-gap: 40px;

    padding: 5rem 10px;  

    @media screen and (max-width: 824px){
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        padding: 3.75rem 10px;
        display: ${props => (props.toggle? "flex" : "none")};
    }

    @media screen and (max-width: 320px) {
        padding: 1.25rem 10px;
        
    }
`

export const ToggleIcon = styled.img`
    transform: ${props => (props.toggle? "rotate(180deg)" : "rotate(90deg)")};
    transition: all 0.5s;
    width: 50px;
    display: none;

    @media screen and (max-width: 824px) {
        display: block;
    }
`
