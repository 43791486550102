import { BrowserRouter as Router, Switch, Route, Redirect, Routes, Navigate } from 'react-router-dom';
import HealthPlan from '../pages/HealthPlan/healthPlan';
import Home from '../pages/Home';
import Plans from '../pages/Plans';
import Units from '../pages/Units';
import Vets from '../pages/Vets';
import SingleVet from '../pages/SingleVet';
import SingleUnit from '../pages/SingleUnit';
import Partners from '../pages/Partners';
import Scheduling from '../pages/Scheduling';
import SinglePartner from '../pages/SinglePartner';

export function AppRoutes() {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/planos' element={<Plans />} />
      <Route path='/planos/cachorros' element={<HealthPlan type={"dog"} />} />
      <Route path='/planos/gatos' element={<HealthPlan type={"cat"} />} />
      <Route path='/medicos-veterinarios' element={<Vets />} />
      <Route path='/medicos-veterinarios/:id' element={<SingleVet />} />
      <Route path='/unidades' element={<Units />} />
      <Route path='/unidades/:id' element={<SingleUnit id={1}/>} />
      <Route path='/parceiros' element={<Partners />} />
      <Route path='/parceiros/:id' element={<SinglePartner />} />
      <Route path='/consulta-online' element={<Scheduling/>} />
      <Route path='*' element={< Navigate to={{ pathname: '/' }} />} />
    </Routes>
  );
}