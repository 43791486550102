import React from "react";
import {CardInfoItemWrapper,
        CardInfoIcon,
        CardInfoItem,
        CardInfoItemName,
        CardInfoItemQuantity,
        Sup
} from './styles'

import checkIcon from '../../assets/images/check.svg'

function HealthPlanCardItem(props){
return(
    <CardInfoItemWrapper>
        <CardInfoIcon src={checkIcon} />
        <CardInfoItem>
            <CardInfoItemName>{props.name}</CardInfoItemName>
        </CardInfoItem>
    </CardInfoItemWrapper>
    )         
}

export default HealthPlanCardItem;