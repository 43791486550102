import styled from "styled-components";

export const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    color: #1C1C1C;
    padding: 100px 2% 60px 2%;
    gap: 10px;
`

 export const Title = styled.h1`
    font-size: 3rem;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;

    @media screen and (max-width: 320px) {
        font-size: 2.25rem;
    }
 `

 export const Subtitle = styled.h2`
    font-size: 2rem;
    font-family: 'Noto Sans', sans-serif; 

    @media screen and (max-width: 320px) {
        font-size: 1.25rem;
    }
 `

export const ResponsivenessWrapper = styled.div`
    color: #FAFAFA;
    background-color: #F28E1F;
    font-family: 'Noto Sans', sans-serif;
    text-align: center;
    padding: 5px 0;
    margin: 1.25rem 0;
    display: none;

    @media screen and (max-width: 824px) {
        display: block;
    }
`

export const ResponsivenessText = styled.h2`
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 2rem;
`
