import React, { useContext } from 'react';

import { ReactSearchAutocomplete } from 'react-search-autocomplete';

import { Container, MainContainer, Wrapper, Title, Image, ResultsContainer, ResultsWrapper, VetDetail, Text, Button } from './styles';

import vet from '../../assets/images/vet.png'
import { useState } from 'react';
import { GlobalContext } from '../../context/GlobalContext';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

function Vets() {
  const context = useContext(GlobalContext);

  useEffect(() => {
    context.handleCities('vets');
  }, [])

  let cities = context.cities;
  cities = cities.filter((elem, index, self) => self.findIndex(
    (t) => { return (t.cidade === elem.cidade) }) === index)

  const handleOnSelect = (item) => {
    context.handleVets(item);
  }

  const handleOnClear = () => {
    context.handleVets();
  }


  const vets = context.vets;

  const navigate = useNavigate();

  return (
    <Container>
      <MainContainer>
        <Wrapper>
          <Title color='#000'>Encontre médicos veterinários e especialistas próximo a você.</Title>
          <ReactSearchAutocomplete
            items={cities}
            fuseOptions={
              { keys: ["cidade"] }
            }
            resultStringKeyName="cidade"
            placeholder='Procure aqui a cidade'
            autoFocus
            onSelect={handleOnSelect}
            onClear={handleOnClear}
            showNoResultsText="Sem resultados"
            styling={{
              borderRadius: "8px",
              backgroundColor: "#E6E6E6",
              color: "#555",
              fontFamily: "Poppins",
              hoverBackgroundColor: "#E0E0E0",
              placeholderColor: "black"
            }}
          />
        </Wrapper>
        {vets.length === 0 && <Image src={vet} />}
      </MainContainer>
      {
        vets.length !== 0 &&
        <ResultsContainer>
          <Title color='#168C5A'>Veterinários em cidade: </Title>
          <ResultsWrapper>
            {
              vets.map(vet => {
                return (
                  <VetDetail key={vet.id}>
                    <Text>{vet.nome} - {vet.especialidades} - {vet.endereco.bairro}</Text>
                    <Button onClick={() => navigate(`/medicos-veterinarios/${vet.id}`)}>VER DETALHES</Button>
                  </VetDetail>
                )
              })
            }

          </ResultsWrapper>
        </ResultsContainer>
      }
    </Container>
  )
}

export default Vets;