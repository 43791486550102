import React, { useState, useEffect } from "react";
import {Card,
        CardTitle,
        CardInfo,
        CardInfoTitleWrapper,
        CardButton,
        CardPriceWrapper,
        CardPrice,
        CardPrice2,
        CardPriceBtnWrapper,
        LinkWrapper,
} from './styles';

import HealthPlanCardItem from "../HealthPlanCardItem"
import HealthPlanCardAdds from "../HealthPlanCardAdds";

function HealthPlanCard({id, tipo, nome, descricao, itens, adesao, valor_a_vista, valor_parcelado, isGreen, adds}){

    const parcels = 12

    const [addsList, setAddsList] = useState([])

    function addItemList(item){
        if(addsList.includes(item)){
            setAddsList(addsList.filter(elem => elem !== item))
        }
        else{
            setAddsList(addsList.concat(item))
        }
    }

    function addsMessage(addsList){
        if(!addsList.length) return " "
        if(addsList.length == 1) return `, com o adicional ${addsList[0]}, `
        else return `, com os adicionais ${addsList.toString().replaceAll(',', ', ')}, `
    }

    const RealFormatter = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
    const [valueTotal, setValueTotal] = useState(valor_a_vista *1)
    const [valueParcels, setValueParcels] = useState(valor_parcelado * 1)

    return(
        <Card>
            <CardInfoTitleWrapper>
                <CardTitle isGreen={isGreen}>
                    {nome}
                </CardTitle>
                <CardInfo>
                    {
                        (typeof(itens) === "string")? "" : (itens)?.map((item, i) => {
                            return(
                                <HealthPlanCardItem key={i} name={item.nome} quantity={item.quantidade} />
                            )
                        }) 
                    }
                    { adds? (
                        <HealthPlanCardAdds adds={adds.resultado} addsList={addItemList} valueTotal={[valueTotal, setValueTotal]} valueParcels={[valueParcels, setValueParcels]} />
                    ) : (
                        <div />
                    )}
                    
                </CardInfo>
            </CardInfoTitleWrapper>          
            <CardPriceBtnWrapper>
                <CardPriceWrapper>
                    <CardPrice>{`${parcels}x de ${RealFormatter.format(valueParcels/ parcels)}`}</CardPrice>
                    <CardPrice2>{`Adesão: ${RealFormatter.format(adesao)}`}</CardPrice2>
                </CardPriceWrapper>
                <LinkWrapper rel="noreferrer" target="_blank" href={`https://api.whatsapp.com/send?phone=5522997935322&text= Olá, gostaria de contratar o plano ${nome}${addsMessage(addsList)}por ${`${parcels}x de ${RealFormatter.format(valueParcels/ parcels)}`} com adesão de ${RealFormatter.format(adesao)} `}>
                    <CardButton isGreen={isGreen}>Contratar Plano</CardButton>
                </LinkWrapper>
            
            </CardPriceBtnWrapper>        
        </Card>
    )    
}

export default HealthPlanCard;
