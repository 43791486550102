import React from "react";
import Banner from "../../components/Banner";
import Benefits from "../../components/Benefits";
import Franchisee from "../../components/Franchisee";
import PlansSection from "../../components/PlansSection";

function Home(){

    return(
        <>
        <Banner/>
        <PlansSection backgroundColor="white" />
        <Benefits/>
        <Franchisee/>
        </>
    )
}

export default Home;