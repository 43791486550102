import styled from "styled-components";

export const ItemWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`

export const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
`

export const BtnsWrapper = styled.div`
    display: flex;
    gap: 5px;
`

export const Name = styled.span``

export const Quantity = styled.span`
    font-size: 1rem;
    color: #3F3F3F;
`

export const Button = styled.img`
`

export const Sup = styled.sup`
font-size: small;
vertical-align: super;

@media screen and (max-width: 824px){
  display: none;
}

`