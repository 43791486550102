// import React, { useState, useEffect } from "react";

// import HealthPlanTitle from "../../components/HealthPlanTitle";
// import HealthPlanCategory from "../../components/HealthPlanCategory";

// function HealthPlan({type}){

//     const dogData = {
//         title: "Planos veterinários para cachorros adultos e filhotes",
//         subtitle: "Confira nossos Planos de Saúde Preventivos disponíveis que contemplam controles vacinais, medicações e acompanhamento veterinário periódico. Veja abaixo o que está incluído em cada Plano.",
//         responsivenessText: "Clique a baixo para ver detalhes dos planos"
//     }

//     const catData = {
//         title: "Planos veterinários para gatos adultos e filhotes",
//         subtitle: "Confira nossos Planos de Saúde Preventivos disponíveis que contemplam controles vacinais, medicações e acompanhamento veterinário periódico. Veja abaixo o que está incluído em cada Plano.",
//         responsivenessText: "Clique a baixo para ver detalhes dos planos"
//     }

//     const data = (type === "dog")? dogData : catData
//     const title = (type === "dog")? "Cachorros" : "Gatos"

//     const [plansData, setPlansData] = useState(null);

//     useEffect(() => {
//         const options = {method: 'GET'};
//         fetch('https://www.redevidaanimal.com/api/v1/v1/planos', options)
//         .then(response =>  response.json())
//         .then(response => {
//             setPlansData({...response})
//         })
//         .catch(err => console.error(err));
//     }, [])
    
//     return(
//         <div>
//             <HealthPlanTitle {...data} />
//             {plansData? (
//                 <div>
//                     <HealthPlanCategory plans={plansData.resultado} size={plansData.total} type={type} title={"Para " + title + " Adultos"} />
//                     <HealthPlanCategory plans={plansData.resultado} size={plansData.total} type={type} title={"Para " + title + " Filhotes"} />
//                 </div>
//             ) : (
//                 <div />
//             )}
//         </div>
//     )
// }

// export default HealthPlan;

import React, { useState, useEffect } from "react";

import HealthPlanTitle from "../../components/HealthPlanTitle";
import HealthPlanCategory from "../../components/HealthPlanCategory";

function HealthPlan({type}){

    const dogData = {
        title: "Planos veterinários dedicados a cachorros adultos e filhotes",
        subtitle: "Os Planos Protetor Rede Vida Animal promovem preventivos em todos os níveis de controle além de acompanhamento médico veterinário periódico. Veja abaixo o que está incluído em cada plano.",
        responsivenessText: "Clique nos botões abaixo para ver mais informações dos planos"
    }

    const catData = {
        title: "Planos veterinários dedicados a gatos adultos e filhotes",
        subtitle: "Os Planos Protetor Rede Vida Animal promovem preventivos em todos os níveis de controle além de acompanhamento médico veterinário periódico. Veja abaixo o que está incluído em cada plano.",
        responsivenessText: "Clique nos botões abaixo para ver mais informações dos planos"
    }

    const data = (type === "dog")? dogData : catData
    const title = (type === "dog")? "Cachorros" : "Gatos"

    const [plansData, setPlansData] = useState(null);

    const [addsData, setAddsData] = useState(null);

    useEffect(() => {
        const options = {method: 'GET'};
        fetch('https://www.redevidaanimal.com/api/v1/adicionais', options)
        .then(response => response.json())
        .then(response => {
            setAddsData({...response})
        })
        .catch(err => console.error(err));
    }, [])

    useEffect(() => {
        const options = {method: 'GET'};
        fetch('https://www.redevidaanimal.com/api/v1/planos', options)
        .then(response => response.json())
        .then(response => {
            response = response.resultado.sort((a,b) => a.valor_parcelado - b.valor_parcelado)
            type === "dog"?
            setPlansData((response.filter(plan => plan.tipo.includes('Canino'))))
            :
            setPlansData((response.filter(plan => plan.tipo.includes('Felino'))))
        })
        .catch(err => console.error(err));     
    }, [type])

    return(
        <div>
            <HealthPlanTitle {...data} />
            {plansData? (
                <div>
                    <HealthPlanCategory plans={plansData.filter(plan => plan.tipo.includes('Adulto'))} type={type} title={"Para " + title + " Adultos"} adds={addsData} />
                    <HealthPlanCategory plans={plansData.filter(plan => plan.tipo.includes('Filhote'))} type={type} title={"Para " + title + " Filhotes"} adds={addsData} />
                </div>
            ) : (
                <div />
            )}
        </div>
    )
}

export default HealthPlan;