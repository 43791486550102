import React from "react";

import { Container, Wrapper, CardContainer, Image, Subtitle, TextContainer, Text, Check, BtnWrapper } from './styles';
import Button from '../Button';

import dog from '../../assets/images/dog.png';
import cat from '../../assets/images/cat.png';
import check from '../../assets/images/check.svg'
import { Link } from "react-router-dom";


function PlansSection({ backgroundColor }) {
  return (
    <Container backgroundColor={backgroundColor}>
      <Wrapper>
        <Image src={dog} />
        <CardContainer>
          <Subtitle>Cachorro Adulto/Filhote</Subtitle>
          <TextContainer><Check src={check} /><Text>Consultas Veterinárias</Text></TextContainer>
          <TextContainer><Check src={check} /><Text>Vacinas</Text></TextContainer>
          <TextContainer><Check src={check} /><Text>Medicações</Text></TextContainer>
          <TextContainer><Check src={check} /><Text>Exames</Text></TextContainer>
          <TextContainer><Check src={check} /><Text>Chip Identificador</Text></TextContainer>
          <TextContainer><Check src={check} /><Text>Lembretes</Text></TextContainer>
          <TextContainer><Check src={check} /><Text>Atestado de Saúde Nacional</Text></TextContainer>
          <TextContainer><Check src={check} /><Text>Carteira de Saúde Digital</Text></TextContainer>
          <TextContainer><Check src={check} /><Text>Clube de Vantagens</Text></TextContainer>
          <Link to="/planos/cachorros"> <BtnWrapper><Button text="Ver Planos" color="orange" /></BtnWrapper> </Link>
        </CardContainer>
      </Wrapper>
      <Wrapper>
        <Image src={cat} />
        <CardContainer>
          <Subtitle>Gato Adulto/Filhote</Subtitle>
          <TextContainer><Check src={check} /><Text>Consultas Veterinárias</Text></TextContainer>
          <TextContainer><Check src={check} /><Text>Vacinas</Text></TextContainer>
          <TextContainer><Check src={check} /><Text>Medicações</Text></TextContainer>
          <TextContainer><Check src={check} /><Text>Exames</Text></TextContainer>
          <TextContainer><Check src={check} /><Text>Chip Identificador</Text></TextContainer>
          <TextContainer><Check src={check} /><Text>Lembretes</Text></TextContainer>
          <TextContainer><Check src={check} /><Text>Atestado de Saúde Nacional</Text></TextContainer>
          <TextContainer><Check src={check} /><Text>Carteira de Saúde Digital</Text></TextContainer>
          <TextContainer><Check src={check} /><Text>Clube de Vantagens</Text></TextContainer>
          <Link to="/planos/gatos"> <BtnWrapper><Button text="Ver Planos" color="orange" /></BtnWrapper> </Link>
        </CardContainer>
      </Wrapper>
    </Container>
  );
}

export default PlansSection;