import styled from "styled-components"

export const PageWrapper = styled.div`
    font-family: 'Noto Sans', sans-serif;
`

export const UpperSection = styled.div`

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;

    gap: 2.5rem;
    margin: 20px;
    margin-top: 6.25rem;
    margin-bottom: 3.75rem;

    @media (max-width: 900px) {
        flex-direction: column;
        align-items: center;
        margin-top: 3.75rem;
    }
`


export const TitleSection = styled.div`
    max-width: 700px;

    h1{
        font-family: Raleway, sans-serif;
        font-size: 3rem;
        font-weight: 700;
        color: #168C5A;
    }

    h2{
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 2rem;
        color: #1C1C1C;

        margin-top: 2.5rem;
    }

    @media (max-width: 900px) {
        width: 100%;

    }

`

export const ParceiroText = styled.p`
font-family: Raleway, sans-serif;
font-size: 2rem;
font-weight: 700;
text-align: center;
margin-top: 20px;
`

export const SearchWrapper = styled.div`
  margin-top: 5rem;
`;

export const Options = styled.div`
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: 50px;

    @media (max-width: 1050px) {
        grid-template-columns: auto auto;
    }

    @media (max-width: 500px) {
        grid-template-columns: auto;
    }
`

export const Option = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 220px;
    height: 220px;

    text-align: center;

    box-sizing: border-box;
    background-color: ${props => (props.isCurrent) ? "#1CAE70" : "#EDEDED"};

    gap: 10px;
    padding: 20px;
    border-radius: 10px;
    cursor: pointer;

    &:hover{
        background-color: ${props => (props.isCurrent) ? "#1CAE70" : "#F28E1F"};
    }

    img{
        width: 128px;
        height: 128px;
    }

    h1{
        font-family: Poppins, sans-serif;
        font-size: 1.5rem;
        font-weight: 600;
    }

`

export const BottomSection = styled.div`
    width: 100%;
`

export const CategoryTitle = styled.div`
    background-color: #168C5A;

    display: flex;
    justify-content: center;

    width: 100%;
    padding: 10px 0px;

    h1{
        font-family: Raleway, sans-serif;
        font-size: 3rem;
        font-weight: 700;
        color: #FAFAFA;
    }
`

export const PartnersCardsWrapper = styled.div`
    display: grid;
    grid-template-columns: ${props => (props.total === 1) ? "auto" : (props.total === 2) ? "auto auto" : "auto auto auto"};
    grid-column-gap: 140px;
    grid-row-gap: 30px;

    padding: 30px;

    @media screen and (max-width: 1150px) {
        grid-template-columns: ${props => (props.total === 1) ? "auto" : "auto auto"};
    }

    @media screen and (max-width: 750px) {
        grid-template-columns: auto;
    }
`