import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.secundaria};
  position: relative;
  overflow: hidden;
`;

export const DogWrapper = styled.div`
  display: flex;
  justify-content: center;


  @media screen and (max-width: 1250px) {
    display: none;
  }

`;

export const DogImg = styled.img`
  height: 30rem;
  z-index: 10;
`;

export const CloudSvg = styled.img`
  position: absolute;
  z-index: 0;
  margin-left: 10rem;

  height: 25rem;
`;

export const DetailsWrapper = styled.div`
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  @media screen and (max-width: 690px) {
    display: none;
  }
`;

export const DetailsRightSvg = styled.img`
  transform: rotate(180deg);

  height: 10rem;
`;

export const HealthPlanSvg = styled.img`
  height: 10rem;


  @media screen and (max-width: 1250px) {
    display: none;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  position: relative;

  @media screen and (max-width: 690px) {
    justify-content: center;
  }
`;

export const BorderDesignRightSvg = styled.img`
  @media screen and (max-width: 1250px) {
    display: none;
  }
`;

export const BorderLeftWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  height: 100%;

  @media screen and (max-width: 690px) {
    display: none;
  }
`;

export const DetailsLeftSvg = styled.img`
  height: 10rem;
  margin-bottom: 5rem;
`;

export const BorderDesignLeftSvg = styled.img``;

export const PlusOutlineLeftSvg = styled.img`
  position: absolute;
  top: 2rem;
  right: 2rem;

  height: 4rem;
`;

export const PlusOutlineRightSvg = styled.img`
  height: 4rem;

  @media screen and (max-width: 1250px) {
    margin-bottom: 15rem;
  }
`;

export const CloudLeftWrapper = styled.div`
  position: relative;
  display: flex;
`;

export const MainContentContainer = styled.div`
  display: flex;
  position: relative;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  margin: 2rem;
  max-width: 335px;
`;

export const LogoContainer = styled.div`
  position: relative;
`;

export const TitleBackgroundSvg = styled.img`

`;

export const Title = styled.h1`
  position:absolute;
  top: calc(50% - 0.25rem);
  left: 50%; 
  width: 100%;
  text-align:center;
  transform: translateX(-50%) translateY(-50%);
  

  color: ${({ theme }) => theme.colors.respiro};
  font-size: 1.5rem;
  font-family: ${({ theme }) => theme.fonts.subtitulo.font_family};
  font-weight: ${({ theme }) => theme.fonts.subtitulo.font_weight};
`;

export const Subtitle = styled.h3`
  color: ${({ theme }) => theme.colors.texto_preto};
  font-size: 1.4rem;
  font-family: ${({ theme }) => theme.fonts.subtitulo.font_family};
  font-weight: ${({ theme }) => theme.fonts.subtitulo.font_weight};

  margin: 0.5rem 0;
`;
export const Text = styled.p`
  color: ${({ theme }) => theme.colors.texto_preto};
  font-size: 1.2rem;
  font-family: ${({ theme }) => theme.fonts.texto_corrido.font_family};
  font-weight: ${({ theme }) => theme.fonts.texto_corrido.font_weight};
  text-align: justify;

  line-height: 2rem;
`;

export const BigText = styled.p`
  color: ${({ theme }) => theme.colors.texto_preto};
  font-size: 2.25rem;
  font-family: ${({ theme }) => theme.fonts.titulo.font_family};
  font-weight: ${({ theme }) => theme.fonts.titulo.font_weight};

  margin: 1rem 0;
`;

export const DownArrowSvg = styled.img``;