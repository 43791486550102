export const modalities = [
    {
        title: 'CONSULTA PRESENCIAL',
        description: 'Realizada por profissional médico veterinário capacitado, presencial no estabelecimento veterinário ou no domicílio do proprietário'
    },
    {
        title: 'VACINAS',
        description: 'Imunizantes importados e éticos aplicados exclusivamente por médico veterinário. Importante ressaltar que a escolha da aplicação da vacina sem consulta é permitido e será realizado por profissional médico veterinário de forma ética e com garantias de eficácia do imunizante, mas sem avaliação clínica ou prescrição de receita sendo esta atribuída a CONSULTA VETERINÁRIA'
    },
    {
        title: 'ESPECIALISTA VETERINÁRIO',
        description: 'Consulta médico veterinário realizada por profissional especialista, tais como dermatologista, cardiologista, ortopedista, anestesista, fisioterapeuta, oncologista, dentre outros. Pode ser exigido pelo médico especialista encaminhamento prévio realizado por veterinário clinico geral em consulta presencial ou tele atendimento.'
    },
    
    {
        title: 'EXAMES LABORATORIAIS',
        description: 'Exames laboratoriais são representados por exame de sangue, urina, fezes, sorologia, citologia, biopsia dentre muitos outros. É obrigatório que a coleta do material biológico e o laudo seja realizado por profissional médico veterinário'
    },
    {
        title: 'EXAMES COMPLEMENTARES',
        description: 'Exames complementares são representados por Raio X, Ultra Som, eletrocardiograma, Tomografia, dentre muitos outros. . É obrigatório que o exame e o laudo sejam realizados por profissional médico veterinário. Pode ser exigido pelo médico especialista o encaminhamento prévio realizado por veterinário clinico geral em consulta presencial ou tele atendimento'
    },
    {
        title: 'CIRURGIAS ELETIVAS E OUTROS PROCEDIMENTOS MÉDICO VETERINÁRIOS',
        description: 'Conduziremos você, no passo a passo em todas etapas necessárias para realização de serviço pretendido, tais como: Castração, limpeza de tártaro, lavagem de ouvido, retirada de bicheiras (miíases), transfusão sanguínea, dentre muitos outros serviços médico veterinário'
    },
    {
        title: 'MEDICAMENTOS DE CONTROLE PREVENTIVO CONTRA PATÓGENOS E VETORES COMUNS',
        description: 'Os medicamentos podem ser injetáveis ou não. A aplicação será realizada por médico veterinário durante atendimento clínico presencial. Pode ser exigido pelo médico uma consulta presencial junto da execução do serviço veterinário. '
    },
    {
        title: 'TELE ATENDIMENTO',
        description: 'Regulamentada pela Resolução do Conselho Federal de Medicina Veterinária (CFMV) nº 1.465/2022. Foi dividida em modalidades de TELE – ORIENTAÇÃO, TELE – TRIAGEM, TELE-DIAGNÓSTICO, TELE INTER-CONSULTA E TELE OCNSULTA (Autorizada somente após o paciente passar pela consulta presencial e mediante documento “RPVAR” (relação prévia veterinária animal-responsável), contendo dados da consulta presencial.'
    }
]