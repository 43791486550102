import React, { useState, useEffect } from "react";

import {
  PageWrapper,
  UpperSection,
  TitleSection,
  SearchWrapper,
  Options,
  Option,
  BottomSection,
  CategoryTitle,
  PartnersCardsWrapper,
  ParceiroText,
  
} from "./styles";

import storeIcon from '../../assets/images/petshop_icon.svg'
import hotelIcon from '../../assets/images/hotel_icon.svg'
import bathingIcon from '../../assets/images/dog_bathing_icon.svg'
import walkIcon from '../../assets/images/dog_walking_icon.svg'
import vetIcon from '../../assets/images/vet_icon.svg'
import taxiIcon from '../../assets/images/taxi_icon.svg'
import urnIcon from '../../assets/images/urn_icon.svg'
import outrosIcon from'../../assets/images/outros_icon.svg'


import { partners } from "./partnersData";

import PartnerCard from "../../components/PartnerCard";
import { ReactSearchAutocomplete } from "react-search-autocomplete";

function Partners() {

  const [currentCity, setCurrentCity] = useState(null);
  const [currentCategory, setCurrentCategory] = useState(null)
  const [currentCategoryName, setCurrentCategoryName] = useState(null)
  const [currentPartners, setCurrentPartners] = useState([])
  const [partnersData, setPartnersData] = useState(null)
  const [cities, setCities] = useState([]);

  useEffect(() => {
    const options = { method: 'GET' };
    fetch('https://www.redevidaanimal.com/api/v1/parceiros', options)
      .then(response => response.json())
      .then(response => setPartnersData(response.resultado))
      .catch(err => console.error(err));
  }, [])

  useEffect(() => {
    setCities([]);
    if (partnersData != null) {
      for (let i = 0; i < partnersData.length; i++) {
        setCities(oldCities => [...oldCities, {id:i, cidade:partnersData[i].endereco.cidade}]);
      }
      setCities(oldCities => oldCities.filter((value, index, self) => {
        return self.findIndex(v => v.cidade === value.cidade) === index;
      }))
    }
  }, [partnersData])
 

  function getPartners(category) {
    if (partnersData) {
      if (currentCity) {
        let temp = partnersData.filter(partner => (partner.endereco.cidade === currentCity));
        temp = temp.filter(partner => partner.categorias[category]);
        setCurrentPartners(temp);
      } else {
          setCurrentPartners(
            partnersData.filter((partner) => partner.categorias[category]))
        }
    }
  }

  const handleOnSelect = (item) => {
    if (currentCategory) {
      let temp = partnersData.filter(partner => (partner.endereco.cidade === item.cidade));
      temp = temp.filter(partner => partner.categorias[currentCategory.toLowerCase()]);
      setCurrentPartners(temp);
    } else {
      setCurrentPartners(partnersData.filter(partners => partners.endereco.cidade === item.cidade));
      setCurrentCategory(item.cidade);
      setCurrentCategoryName(item.cidade)
    }
    setCurrentCity(item.cidade);
  }

  const handleOnClear = () => {
    if (currentCategory !== currentCity) setCurrentPartners(partnersData.filter((partner) => partner.categorias[currentCategory.toLowerCase()]));
    else { setCurrentPartners([]); setCurrentCategory(null); }
    setCurrentCity(null);
  }

  const dic = {
          "Loja pet":storeIcon,
          "Hospedagem":hotelIcon,
          "Banho e tosa":bathingIcon,
          "Adestrador":walkIcon,
          "Auxiliar Veterinário":vetIcon,
          "Transporte Animal":taxiIcon,
          "Crematório":urnIcon,
          "Outros":outrosIcon,
  }

  return (
    <PageWrapper>
      <UpperSection>
        <TitleSection>
          <h1> Parceiros </h1>
          <h2> Procure por um dos nossos parceiros que indicamos em sua cidade e tenha um atendimento de qualidade, benefícios, descontos, e muito mais! </h2>
          <h2>Basta selecionar sua cidade e uma categoria a seguir </h2>

          <SearchWrapper>
            <ReactSearchAutocomplete
              items={cities}
              fuseOptions={
                { keys: ["cidade"] }
              }
              resultStringKeyName="cidade"
              placeholder='Procure aqui a cidade'
              autoFocus
              onSelect={handleOnSelect}
              onClear={handleOnClear}
              showNoResultsText="Sem resultados"
              styling={{

                borderRadius: "8px",
                backgroundColor: "#E6E6E6",
                color: "#555",
                fontFamily: "Poppins",
                hoverBackgroundColor: "#E0E0E0",
                placeholderColor: "black"
              }}
            />
          </SearchWrapper>
        </TitleSection>
        <Options>
          <Option onClick={() => {setCurrentCategoryName("Loja pet"); setCurrentCategory('loja_pet'); getPartners('loja_pet') }} isCurrent={(currentCategory === 'loja_pet')} >
            <img src={storeIcon} alt="" />
            <h1> Loja pet </h1>
          </Option>
          <Option onClick={() => {setCurrentCategoryName("Hospedagem"); setCurrentCategory('hospedagem'); getPartners('hospedagem') }} isCurrent={(currentCategory === 'hospedagem')} >
            <img src={hotelIcon} alt="" />
            <h1> Hospedagem </h1>
          </Option>
          <Option onClick={() => {setCurrentCategoryName("Banho e tosa"); setCurrentCategory('banho_tosa'); getPartners('banho_tosa') }} isCurrent={(currentCategory === 'banho_tosa')} >
            <img src={bathingIcon} alt="" />
            <h1> Banho e tosa </h1>
          </Option>
          <Option onClick={() => {setCurrentCategoryName("Adestrador/criador"); 
          setCurrentCategory('adestrador_criador'); getPartners('adestrador_criador') }} isCurrent={(currentCategory === 'adestrador_criador')} >
            <img src={walkIcon} alt="" />
            <h1> Adestrador/criador </h1>
          </Option>
          <Option onClick={() => {setCurrentCategoryName("Auxiliar Veterinário"); setCurrentCategory('auxiliar_vet'); getPartners('auxiliar_vet') }} isCurrent={(currentCategory === 'auxiliar_vet')} >
            <img src={vetIcon} alt="" />
            <h1> Auxiliar Veterinário </h1>
          </Option>
          <Option onClick={() => {setCurrentCategoryName("Transporte Animal"); setCurrentCategory('transporte_animal'); getPartners('transporte_animal') }} isCurrent={(currentCategory === 'transporte_animal')} >
            <img src={taxiIcon} alt="" />
            <h1> Transporte Animal </h1>
          </Option>
          <Option onClick={() => {setCurrentCategoryName("Crematório"); setCurrentCategory('crematorio'); getPartners('crematorio') }} isCurrent={(currentCategory === 'crematorio')} >
            <img src={urnIcon} alt="" />
            <h1> Crematório </h1>
          </Option>
          <Option onClick={() => {setCurrentCategoryName("Outros"); setCurrentCategory('outros'); getPartners('outros') }} isCurrent={(currentCategory === 'outros')} >
            <img src={outrosIcon} alt="" />
            <h1> Outros </h1>
          </Option>
        </Options>
      </UpperSection>
      <BottomSection>
        {currentCategory ? (
          <>
            <CategoryTitle>
              <h1>{currentCategoryName.toUpperCase()}</h1>
            </CategoryTitle>
            {currentCity && currentCategory && currentPartners.length===0 && <ParceiroText> Sem parceiros em sua cidade no momento <br></br> </ParceiroText>}
            <PartnersCardsWrapper total={currentPartners.length}>
              {currentPartners.map((partner) =>
              (<PartnerCard key={partner.id} id={partner.id} name={partner.nome} city={partner.endereco.cidade}
                specialties={partner.especialidades} benefits={partner.servicos} />))}
            </PartnersCardsWrapper>
          </>
        ) : (<div />)}
      </BottomSection>
    </PageWrapper>
  )
}

export default Partners