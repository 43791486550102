import styled from 'styled-components';

export const Container = styled.button`
  background-color: ${({ theme, color }) => (
    color === 'green' ? theme.colors.green_btn : theme.colors.call_to_action
  )};
  
  font-family: ${({ theme }) => theme.fonts.subtitulo.font_family};
  font-size: 1.25rem;

  border: none;
  border-radius: 8px;

  padding: .2rem 1.25rem;
  margin: 0 .5rem;

  cursor: pointer;
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.respiro};
`;
