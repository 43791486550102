import styled from "styled-components";

import {ReactComponent as whatsapp_icon} from '../../assets/images/whatsapp_icon.svg'


const Colors = {
    titleContainer: '#F28E1F',
    title: '#FAFAFA',
    text: '#1C1C1C',
    mainContainer: '#FAFAFA',
    subSection: '#168C5A'
}

export const Wrapper = styled.div`
    text-align: center;
    font-family: 'Noto Sans', sans-serif;
    color: ${Colors.text};
    margin-bottom: 2rem;
`

export const TitleContainer = styled.div`
    background-color: ${Colors.titleContainer};   
    padding: 10px;
`

export const Title = styled.h1`
    color: ${Colors.title};
    font-size: 3rem;
    font-family: Raleway, sans-serif;
    font-weight: 700;

    @media screen and (max-width: 318px){
        font-size: 2rem;
        margin: 10px 0;
    }
`
export const Subtitle  = styled.h2`
    font-size: 1.8rem;
    margin: 20px 20% 50px 20%;
 
    @media screen and (max-width: 834px){ 
        margin-left: 10px;
        margin-right: 10px;
    }

    @media screen and (max-width: 318px){
        font-size: 24px; 
    }
`

export const SubSectionWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;

    justify-content: center;
    align-items: stretch;

    gap: 50px 100px;

    margin: 10px 4rem; 

    @media screen and (max-width: 318px){ 
        margin: 10px;
    }
`
export const SubSectionForWrap = styled.div`
    display: flex;
    gap: 50px 100px;

    justify-content: center;
    align-items: stretch;

    @media screen and (max-width: 619px) {
        flex-wrap: wrap;
    }
`

export const SubSection = styled.div`
    width: 228px;
    min-width: 228px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 10px 25px 10px;
    gap: 20px;
    border: 1px solid #000000;
    filter: drop-shadow(0px 8px 8px rgba(0, 0, 0, 0.1));
    border-radius: 5px;
    position: relative;
`
export const SubSectionTitle = styled.div`
    width: 178px;
    height: 54px;
    position: absolute;
    background-color: ${Colors.subSection};
    border-radius: 20px;
    padding: 5px 10px;
    color: ${Colors.title};
    top: -27px;

    display: flex;
    justify-content: center;
    align-items: center;
`
export const SubSectionName = styled.span`
    font-weight: 500;
`

export const SubSectionText = styled.p`
    font-size: 20px;
    margin: 10px;
`

export const WppIcon = styled(whatsapp_icon)`
  width: 60px;
  height: 60px;

  &:hover path {
    fill: orange;
  }
`