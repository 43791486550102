import { useState } from "react";
import api from "../../services/api";

export function useContext() {

  const [vet, setVet] = useState({});
  const [vets, setVets] = useState([]);
  const [unit, setUnit] = useState({});
  const [units, setUnits] = useState([]);
  const [cities, setCities] = useState([]);

  const handleCities = (page) => {
    setCities([]);
    if (page === 'units') {
      api.get('/unidades')
        .then(res => {
          let temp = res.data.resultado;
          for (let i = 0; i < temp.length; i++) {
            setCities((oldCities) => { oldCities.push(temp[i].endereco); return [...oldCities] })
          }
        })
        .catch(err => {let a = err});
    } else if (page === 'vets') {
      api.get('/veterinarios')
        .then(res => {
          let temp = res.data.resultado;
          for (let i = 0; i < temp.length; i++) {
            setCities(oldCities => [...oldCities, temp[i].endereco])
          }
        })
        .catch(err => {let a = err});
    }
  }

  const handleVets = (endereco) => {
    setVets([]);
    api.get('/veterinarios')
      .then(res => {
        let temp = res.data.resultado;
        for (let i = 0; i < temp.length; i++) {
          if (temp[i].endereco.cidade === endereco.cidade) {
            setVets(oldVets => [...oldVets, temp[i]])
          };
        }
      })
      .catch((error) => {
         let a = error
      })
  }

  const handleSingleVet = (id) => {
    setVets([]);
    api.get('/veterinarios')

      .then(res => {
        let temp = res.data.resultado;
        for (let i = 0; i < temp.length; i++) {
          if (temp[i].id == id) {
            setVet(temp[i]);
            break;
          };
        }
      })
      .catch((error) => {
         let a = error
      })
  }

  const handleUnits = (endereco) => {
    setUnits([]);
    api.get('/unidades')
      .then(res => {
        let temp = res.data.resultado;
        for (let i = 0; i < temp.length; i++) {
          if (temp[i].endereco.cidade === endereco.cidade) {
            setUnits(oldUnits => [...oldUnits, temp[i]])
          };
        }
      })
      .catch((error) => {
         let a = error
      })
  }

  const handleSingleUnit = (id) => {
    setUnits([]);
    api.get('/unidades')
      .then(res => {
        let temp = res.data.resultado;
        for (let i = 0; i < temp.length; i++) {
          if (temp[i].id == id) {
            setUnit(temp[i]);
            break;
          };
        }
      })
      .catch((error) => {
         let a = error
      })
  }


  return { handleCities, cities, handleVets, vets, handleUnits, units, handleSingleVet, vet, handleSingleUnit, unit }
}

