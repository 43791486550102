import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { useParams } from "react-router-dom";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './Carousel.css';
import {
    Container,
    UpperSection,
    InfoSection,
    Bold,
    UnitTitle,
    UnitSubtitle,
    UnitName,
    UnitServices,
    UnitHours,
    UnitPhones,
    UnitDescription,
    LinksSection,
    AddressSection,
    UnitAddress,
    UnitMap,
    BottomSection,
    UnitVideo,
    CarouselWrapper,
    CarouselImg,
    MainImg,
    InstaMediaIcon,
    WppMediaIcon,
    Wrapper,
    VideoWrapper
} from './styles';

import whatsapp from "../../assets/images/whatsapp_icon_green.svg"
import instagram from "../../assets/images/instagram_icon_green.svg"
function SinglePartner(props){
    
    const [partnerData, setPartnerData] = useState(null)
    const [videoLink, setVideoLink] = useState("")
    const [address, setAdress] = useState("")
    const { id } = useParams();
    // const arrowStyles = {
    //     position: 'absolute',
    //     zIndex: 2,
    //     top: '0px',
    //     width: 45,
    //     height: '100%',
    //     cursor: 'pointer',
    //     backgroundColor: '#1CAE70',
    //     opacity: 0.1,
    //     border: 'none',
    //     "&:hover": {
    //         opacity: 0.8,
    //     }
    // }
    // renderArrowPrev={(onClickHandler, hasPrev, label) =>
    //     hasPrev && (
    //         <button type="button" onClick={onClickHandler} title={label} style={{...arrowStyles,left: 0 }}>
    //             {`<`}
    //         </button>
    //     )
    // }
    // renderArrowNext={(onClickHandler, hasNext, label) =>
    //     hasNext && (
    //         <button type="button" onClick={onClickHandler} title={label} style={{...arrowStyles, right: 0 }}>
    //             {`>`}
    //         </button>
    //     )
    // }

    let images = []

    if (partnerData){
        let photos = []
        if(partnerData.midias?.imagem_principal){
          photos.push(partnerData.midias.imagem_principal)
        }
        if(partnerData.midias?.imagens){
            partnerData.midias.imagens.forEach(img => {
              photos.push(img)
            })
          }
        images = photos
      }

    useEffect(() => {
        const options = {method: 'GET'};
        fetch('https://www.redevidaanimal.com/api/v1/parceiros', options)
        .then(response => response.json())
        .then(response => {
            response.resultado.forEach((partner) => {
              if(partner.id === parseInt(id)) {
                    setPartnerData(partner)
                    if (partner.midias.video_institucional.length>10){
                        setVideoLink(partner.midias.video_institucional.split("=")[1])
                    }else{
                        setVideoLink(null)
                    }
                    setAdress(partner.endereco.logradouro + " " + partner.endereco.numero + " " + partner.endereco.bairro + " " + partner.endereco.cidade)
                }
            })
        })
        .catch(err => console.error(err));
    }, [])
    return(
        <>
    {partnerData? (
        <Container>
            <UpperSection>
                <InfoSection>
                    <UnitTitle>{partnerData.nome}</UnitTitle>
                    <MainImg src={partnerData.midias.imagem_principal} />
                    <Wrapper><UnitSubtitle>{partnerData.subtitulo}</UnitSubtitle></Wrapper>
                    <Wrapper><UnitServices><Bold>Serviços: </Bold>{partnerData.servicos}</UnitServices></Wrapper>
                    <Wrapper><UnitServices><Bold>Especialidades: </Bold>{partnerData.especialidades}</UnitServices></Wrapper>
                    <Wrapper><UnitHours><Bold>Horário de Funcionamento: </Bold>{partnerData.horarioFuncionamento}</UnitHours></Wrapper>
                    <Wrapper><UnitPhones><Bold>Telefone: </Bold>{partnerData.telefone}</UnitPhones></Wrapper>
                    <Wrapper><UnitPhones><Bold>Site: </Bold><a rel="noreferrer" target="_blank" href={partnerData.site}>{partnerData.site}</a></UnitPhones></Wrapper>
                    <UnitDescription>{partnerData.descricao}</UnitDescription>
                    <LinksSection>
                        <a href={`${partnerData.whatsapp}`}>
                            <WppMediaIcon alt="Ícone Whatsapp" />
                        </a>
                        <a href={partnerData.instagram}>
                            <InstaMediaIcon alt="Ícone Instagram" />
                        </a>
                    </LinksSection>
                </InfoSection>
            <CarouselWrapper>
                <Carousel 
                    autoPlay 
                    dynamicHeight={false} 
                    infiniteLoop 
                    showStatus={false} 
                    showThumbs={false} 
                    showIndicators
                >
                    {images && Object.keys(images).map((imagem, i) => {
                  return (
                    <div key={i}><CarouselImg src={images[imagem]} /></div>
                    )}
                  )}
                </Carousel>
            </CarouselWrapper>
            </UpperSection>
            <BottomSection>
                <AddressSection>
                    <h1>Local de atendimento</h1>
                    <UnitAddress>Endereço: {partnerData.endereco.logradouro}, {partnerData.endereco.numero}, {partnerData.endereco.bairro}, {partnerData.endereco.cidade}</UnitAddress>
                    <UnitMap src={`https://www.google.com/maps?q=${address}&output=embed`} />
                </AddressSection>
               {videoLink &&
               <VideoWrapper>
                   <h1>Video Institucional</h1>
                   <UnitVideo src={`https://www.youtube.com/embed/${videoLink}`} />
               </VideoWrapper>
                }
            </BottomSection> 
        </Container>
    
    ) : (<div />)}
    </>
    )
}

export default SinglePartner