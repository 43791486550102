import styled from 'styled-components';

import footprints from '../../assets/images/footprints.png';
import singleFootprint from '../../assets/images/singleFootprint.png';

export const CardContainer = styled.div`
  width: 200px;
  padding: 30px 20px;

  border: 1px solid ${({ theme }) => theme.colors.texto_corrido};
  border-radius: 10px;

  background-color: ${({ theme }) => theme.colors.respiro};
`;

export const Wrapper = styled.div`
  position: relative;

  margin: 0 2rem;

  @media screen and (max-width: 880px) {
    &:last-child {
      margin-top: 20rem;
    }
  }
  
  @media screen and (max-width: 610px) {
    &:last-child {
      margin-top: 0;
    }
  }
`;

export const Container = styled.div`
  position: relative;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;

  padding: 170px 0 30px;
  height: 100%;

  background-color: ${({ theme, backgroundColor }) => (
    backgroundColor === 'white' ? theme.colors.respiro : theme.colors.secundaria
  )};
  background-image: url(${footprints});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  
  @media screen and (max-width: 1000px) {
    background-image: url(${singleFootprint});
    background-repeat: no-repeat;
    background-size: auto;
  }

  @media screen and (max-width: 612px) {
    ${Wrapper}:first-child {
      margin-bottom: 300px
    }
  }
`;

export const Image = styled.img`
  position: absolute;
  top: -147px;
  left: 20px;
`;

export const Subtitle = styled.h2`
  font-size: 1.5rem;
  font-family: ${({ theme }) => theme.fonts.subtitulo.font_family};
  font-weight: ${({ theme }) => theme.fonts.subtitulo.font_weight};
  color: ${({ theme }) => theme.colors.texto_preto};

  text-align: center;
  margin-bottom: 10px;
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 10px;
`;

export const Text = styled.p`
  font-size: 1.25rem;
  font-family: ${({ theme }) => theme.fonts.texto_corrido.font_family};
  font-weight: ${({ theme }) => theme.fonts.texto_corrido.font_weight};
  color: ${({ theme }) => theme.colors.texto_preto};
`;

export const Check = styled.img`
  margin-right: .25rem;
`;

export const BtnWrapper = styled.div`
  text-align: center;
`;