import React from "react"
import { Wrapper, Infos, Info } from "./styles"

import {modalities} from "../../pages/Scheduling/modalities.js"

function SchedulingInfo(){
    return(
        <Wrapper>
            <h1>Nós possuímos atendimento previamente agendado nas seguintes modalidades:</h1>
            <Infos>
                {modalities.map((item) => {
                    return(
                        <Info key={item.title}>
                            <h2>{item.title + ': '}</h2>
                            <p>{item.description}</p>
                        </Info>
                    )
                })}
            </Infos>
        </Wrapper>
    )
}

export default SchedulingInfo