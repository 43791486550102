import styled from 'styled-components';

export const Container = styled.div``;

export const QuestionContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.principal};
  display: flex;
  max-width: 800px;
  margin-top: 0.1rem;
  padding: 1rem;
  align-items: center;
  border-bottom: none;

  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.menu_hover};
  }
`;

export const AnswerContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.secundaria};
  border: 1px solid ${({ theme }) => theme.colors.principal};
  max-width: 798px;
  padding: 1rem;
`;

export const QuestionText = styled.p`
  font-size: 1.25rem;
  white-space: pre-line;
  font-family: ${({ theme }) => theme.fonts.subtitulo.font_family};
  font-weight: ${({ theme }) => theme.fonts.subtitulo.font_weight};
  color: ${({ theme }) => theme.colors.respiro};
`;

export const AnswerText = styled.p`
  font-size: 1rem;
  text-align: justify;
  white-space: pre-line;
  font-family: ${({ theme }) => theme.fonts.subtitulo.font_family};
  font-weight: ${({ theme }) => theme.fonts.subtitulo.font_weight};
  color: ${({ theme }) => theme.colors.texto_preto};
`;

export const ArrowIcon = styled.img`
   ${({ isOpen }) => {
    if (!isOpen) return `
      transform: rotate(270deg);
    `;
  }}
  margin: 2px 5px 0 0;
`;
