import styled from "styled-components";

const Colors = {
    container: '#85DAB6',
    text: '#1C1C1C',
    topic: '#FAFAFA'
}

export const Container = styled.div`
    background-color: ${Colors.container};
    color: ${Colors.text};
    padding: 5rem;
    font-family: 'Noto Sans', sans-serif;

    @media screen and (max-width: 834px){
        padding: 5rem 2rem;
    }

    @media screen and (max-width: 318px){
        padding: 5rem 10px;
    }
`

export const Title = styled.h1`
    display: block;
    font-family: Raleway, sans-serif;
    font-size: 3rem;
    text-align: center;
    font-weight: 700;

    @media screen and (max-width: 318px){
        font-size: 2rem;
    }
`

export const TopicsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 3rem;
    margin: 2.5rem 0;
`

export const Topic = styled.div`
    background-color: ${Colors.topic};
    display: flex;
    flex-direction: column;
    width: 200px;
    height: 200px;
    gap: 10px;
    border-radius: 50%;

    justify-content: center;
    align-items: center;

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
`

export const TopicIcon = styled.img`
    width: 64px;
    height: 64px;
`

export const Text = styled.p`
    font-size: 20px;
    text-align: center;
`