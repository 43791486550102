import React from 'react';
import { Container, Image, MenuContainer, Wrapper, Icon } from './styles';

import logo from '../../assets/images/logo.png'
import { Twirl as Hamburger } from 'hamburger-react'

import Button from '../Button';
import { useState } from 'react';
import Menu from '../Menu';
import { Link } from 'react-router-dom';

function Header() {
  const [isOpen, setOpen] = useState(false)
  return (
    <Container>
      <Link to='/'>
        <Image src={logo} />
      </Link>
      
      <MenuContainer>
        <Wrapper>
          <a href='https://www.redevidaanimal.com/'>
          <Button text="Espaço do cliente" color="green" />
          </a>
          
          <Link to='/consulta-online'> 
            <Button text="Agendar Consulta" color="orange" />
          </Link>
        </Wrapper>
        <Hamburger toggled={isOpen} toggle={setOpen} />
        {
          isOpen ? <Menu toggle={setOpen} /> : <></>
        }
      </MenuContainer>
    </Container>
  );
}

export default Header;