import styled from "styled-components";

export const CardContainer = styled.div`
    background-color: #168C5A;
    color: #FAFAFA;

    font-size: 1.25rem;
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    max-width: 200px;
    min-height: 420px;
    gap: 10px;
    padding: 2rem 3rem;
    margin: auto;

    border-radius: 10px;

    img{
        width: 80%;
    }
`

export const PartnerInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
`

export const Wrapper = styled.div`

`

export const ScheduleBtn = styled.div`
    background-color: #F28E1F;

    font-family: Poppins, sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    
    display: flex;
    justify-content: center;

    width: 150px;
    padding: 10px;

    border-radius: 8px;

    cursor: pointer;
    a:hover & {
        background-color: greenyellow;
        color: black;
    }

`