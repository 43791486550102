import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;

    background-color: #85DAB6;

    font-family: 'Noto Sans', sans-serif;
    font-size: 24px;
    color: #1C1C1C;

    padding: 5%;
    padding-bottom: 20px;
    padding-top: 4em;
    gap: 40px;
`

export const BannerContainer = styled.div`
    display: flex;
    gap: 40px;
    align-items: flex-start;
    justify-content: space-evenly;

    @media screen and (max-width: 950px){
        flex-direction: column;
        align-items: center;
    }
`

export const BannerTitle = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    max-width: 440.5px; 
    padding: 10px;
    gap: 10px;

    h1{
        font-family: Raleway, sans-serif;
        font-size: 48px;
        font-weight: 700;

        @media screen and (max-width: 320px){
            font-size: 36px;
            text-align: center ;
        }
    }

    img{
        width: 100%;
    }
`

export const BannerList = styled.div`
    display: flex;

        h1{
            font-weight: 700;
            margin-bottom: 2.5rem;
            line-height: 110%;
            text-align: center;
        }

        li{
            display: flex;
            align-items: center;

            margin-bottom: 2.5em;
            gap: 20px;
        }
        span{
            color: #168C5A;
            cursor: pointer;
            font-weight: 600;
            text-decoration: underline;

            &:hover{
                color: #F28E1F;
            }
        }
`

export const ListMarker = styled.div`
    font-family: Raleway, sans-serif;
    color: #FFFFFF;
    font-size: 48px;
    font-weight: 700;
    text-align: center;

    background-color: #F28E1F;

    min-height: 60px;
    min-width: 60px;

    border-radius: 50%;
`

export const BannerPointer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    p{
        text-align: center;
        font-weight: 500;

        width: 313px;
    }

    img{
        width: 60px;
        height: 60px;
    }
`