import React from "react";
import {TitleWrapper,
        Title,
        Subtitle,
        ResponsivenessWrapper,
        ResponsivenessText,
        CliqueAbaixo
} from "./styles";

function HealthPlanTitle({title, subtitle, responsivenessText}){
    return(
        <div>
            <TitleWrapper>
                <Title>
                    {title}
                </Title>
                <Subtitle>
                    {subtitle}
                </Subtitle>
            </TitleWrapper>
            <ResponsivenessWrapper>
                <ResponsivenessText>
                    {responsivenessText}
                </ResponsivenessText>
            </ResponsivenessWrapper>
        </div>
    )
}

export default HealthPlanTitle;