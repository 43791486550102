import React from "react";

import {Container,
        Title,
        TopicsWrapper,
        Topic,
        TopicIcon, 
        Text
} from './styles'

import nurse_icon from '../../assets/images/nurse_icon.svg'
import injection_icon from '../../assets/images/injection_icon.svg'
import prescription_icon from '../../assets/images/prescription_icon.svg'

function Benefits() {
    return (
        <Container>
            <Title>
            Benefícios do Plano de Saúde Preventivo Protetor
            </Title>
            <TopicsWrapper>
                <Topic>
                    <TopicIcon src={injection_icon} />
                    <Text>
                        Controle de vacinação 
                    </Text>
                </Topic>
                <Topic>
                    <TopicIcon src={prescription_icon} />
                    <Text>
                        Prevenção e medicação
                    </Text>
                </Topic>
                <Topic>
                    <TopicIcon src={nurse_icon} />
                    <Text>
                    Cuidados veterinários
                    </Text>
                </Topic>
            </TopicsWrapper>
            <Text>
            Nosso maior valor é a saúde do seu Pet, nossa missão não deixar seu Pet adoecer. Todos ganham e colecionam memórias de felicidade. <br/> De geração a geração, fala o popular: prevenir é melhor que remediar!
            </Text>
        </Container>
    )
}
export default Benefits