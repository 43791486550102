import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './Carousel.css';
import {
    Container,
    UpperSection,
    InfoSection,
    Bold,
    UnitTitle,
    UnitSubtitle,
    UnitName,
    UnitServices,
    UnitHours,
    UnitPhones,
    UnitDescription,
    LinksSection,
    AddressSection,
    UnitAddress,
    UnitMap,
    BottomSection,
    UnitVideo,
    CarouselWrapper,
    CarouselImg,
    MainImg,
    InstaMediaIcon,
    WppMediaIcon,
    Wrapper,
    VideoWrapper,
    Highlight
} from './styles';

import whatsapp from "../../assets/icons/unit_whatsapp.svg"
import instagram from "../../assets/icons/unit_instagram.svg"
import { useContext } from "../../context/hooks/useContext";
import { GlobalContext } from "../../context/GlobalContext";
import { useParams } from "react-router-dom";
import SchedullingPopUp from '../../components/SchedullingPopUp';


function SingleUnit(props) {
    const { id } = useParams();
    const [videoLink, setVideoLink] = useState("")
    const context = useContext(GlobalContext);
    
    useEffect(() => {
      context.handleSingleUnit(id);

    }, [])
    
    const [isOpen, setIsOpen] = useState(false)
    const body = document.getElementsByTagName("body")[0];
    
    function togglePopUp(){
      if(!body.classList.contains("hidden")){
        body.classList.add("hidden")
      }
      else{
        body.classList.remove("hidden")
      }
      setIsOpen(!isOpen)
    }
    
    const unit = context.unit;
    
    let images = []

    if (unit){
      let photos = []
      if(unit.midias?.imagem_principal){
        photos.push(unit.midias.imagem_principal)
      }
      if(unit.midias?.imagens){
          unit.midias.imagens.forEach(img => {
            photos.push(img)
          })
        }
      images = photos
    }

    return (
      <>
      <SchedullingPopUp tel={`${unit.whatsapp}&text=`} isOpen={isOpen} toggle={togglePopUp} name={unit.nome} />
      <Container>
        {Object.keys(unit).length !== 0 &&
          <>
            <UpperSection>
              <InfoSection>
                <UnitTitle>{unit.nome}</UnitTitle>
                <MainImg src={unit.midias.imagem_principal} />
  
                <UnitSubtitle>{unit.subtitulo}</UnitSubtitle>
                <Wrapper><UnitServices><Bold>Especialidades: </Bold>{unit.especialidades}</UnitServices></Wrapper>
                <Wrapper><UnitServices><Bold>Serviços: </Bold>{unit.servicos}</UnitServices></Wrapper>
                <Wrapper><UnitHours><Bold>Horário de Funcionamento: </Bold>{unit.horarioFuncionamento}</UnitHours></Wrapper>
                <Wrapper><UnitPhones><Bold>Telefone: </Bold>{unit.telefone}</UnitPhones></Wrapper>
                <Wrapper><UnitDescription>{unit.descricao}</UnitDescription></Wrapper>
                <Wrapper><UnitDescription>Agende a consulta, vacina, ou outros serviços médico veterinários para o seu pet <Highlight onClick={togglePopUp}>clicando aqui</Highlight></UnitDescription></Wrapper>
                <LinksSection>
                 <a rel="noreferrer" target="_blank" href={`${unit.whatsapp}`}><WppMediaIcon/></a>
                 <a href={`${unit.instagram}`} target="#"><InstaMediaIcon/> </a>
                </LinksSection>
              </InfoSection>
              <CarouselWrapper>
              <Carousel
                autoPlay
                dynamicHeight={false}
                infiniteLoop
                showStatus={false}
                showThumbs={false}
                showIndicators
                >
                {images && Object.keys(images).map((imagem, i) => {
                  return (
                    <div key={i}><CarouselImg src={images[imagem]} /></div>
                    )}
                  )}
              </Carousel>
            </CarouselWrapper>
            </UpperSection>
            <BottomSection>
              <AddressSection>
                <div>
                  <h1>Local de atendimento</h1>
                  <UnitAddress>Endereço: {unit.endereco.logradouro}, {unit.endereco.numero}, {unit.endereco.bairro}, {unit.endereco.cidade}</UnitAddress>
                </div>
                <UnitMap src={`https://www.google.com/maps?q=${unit.endereco.logradouro + " " + unit.endereco.numero + " " + unit.endereco.bairro + " " + unit.endereco.cidade}&output=embed`} />
              </AddressSection>
              {unit.midias.video_institucional.length>10 &&
              <VideoWrapper>
                <h1>Video Institucional</h1>
                <UnitVideo src={`https://www.youtube.com/embed/${unit.midias.video_institucional.split("=")[1]}`} />
              </VideoWrapper>
              }
            </BottomSection>
          </>
  
}
      </Container>
      </>
    )
  }

export default SingleUnit
