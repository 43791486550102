import React from "react";

import {Wrapper,
        TitleContainer,
        Title,
        Subtitle,
        SubSectionWrapper,
        SubSection,
        SubSectionTitle,
        SubSectionText,
        SubSectionName,
        WppIcon,
        SubSectionForWrap,
} from './styles'

function Franchisee(){
    return(
        <Wrapper>
            <TitleContainer>
                <Title>
                    Seja um franqueado
                </Title>
            </TitleContainer>
            <Subtitle>
                De acordo com projeções do censo do Conselho Federal de Medicina Veterinária, o Brasil possuí mais de 156.000 veterinários com média de 12.300 novos veterinários formados por ano. Esse enorme crescimento se deve muito ao Brasil ser o 3º maior pais em proporção de animais domésticos por residência. É um mercado que vem crescendo bastante, e o Rede Vida Animal é a oportunidade pra você expandir seu negócio.
            </Subtitle>
            <SubSectionWrapper>
                <SubSectionForWrap>
                    <SubSection>
                        <SubSectionTitle>
                            FRANQUIA
                        </SubSectionTitle>
                        <SubSectionText>
                            <SubSectionName>Sou empreendedor </SubSectionName>e tenho interesse em adquirir uma franquia 
                        </SubSectionText>
                        <a rel="noreferrer" target="_blank" href="https://api.whatsapp.com/send?phone=5522997935322&text=Sou empreendedor e tenho interesse em adquirir uma franquia ">
                            <WppIcon />
                        </a>
                    </SubSection>
                    <SubSection>
                        <SubSectionTitle>
                            MÉDICO VETERINÁRIO
                        </SubSectionTitle>
                        <SubSectionText>
                            <SubSectionName>Quero ser conveniado</SubSectionName>  e otimizar minha produtividade.
                        </SubSectionText>
                        <a rel="noreferrer" target="_blank" href="https://api.whatsapp.com/send?phone=5522997935322&text= Sou médico veterinário e quero ser conveniado do Rede Vida Animal e otimizar minha produtividade.">
                            <WppIcon />
                        </a>
                    </SubSection>
                </SubSectionForWrap>
                <SubSectionForWrap>
                    <SubSection>
                        <SubSectionTitle>
                            CONSULTÓRIO/CLINICA
                        </SubSectionTitle>
                        <SubSectionText>
                        <SubSectionName>Quero ser conveniado</SubSectionName>  e otimizar minha produtividade.
                        </SubSectionText>
                        <a rel="noreferrer" target="_blank" href="https://api.whatsapp.com/send?phone=5522997935322&text= Sou um consultório ou clinica e quero ser conveniado do Rede Vida Animal e otimizar minha produtividade.">
                            <WppIcon />
                        </a>
                    </SubSection>
                    <SubSection>
                        <SubSectionTitle>
                            PARCEIRO
                        </SubSectionTitle>
                        <SubSectionText>
                            <SubSectionName>Sou do segmento</SubSectionName>  e quero fazer parte do "Clube de Vantagens"  e receber indicações
                        </SubSectionText>
                        <a rel="noreferrer" target="_blank" href="https://api.whatsapp.com/send?phone=5522997935322&text=Sou do segmento e gostaria de fazer uma parceria com o Rede Vida Animal, fazer parte do Clube de Vantagens e receber indicações.">
                            <WppIcon />
                        </a>
                    </SubSection>
                </SubSectionForWrap>
            </SubSectionWrapper>
        </Wrapper>
    )
}

export default Franchisee