import styled from 'styled-components';
import arrowGray from '../../assets/images/arrow_gray.svg';
import arrowWhite from '../../assets/images/arrow_white.svg';


import {ReactComponent as instagramIconGreen} from '../../assets/images/instagram_icon_green.svg';
import {ReactComponent as whatsappIconGreen} from '../../assets/images/whatsapp_icon_green.svg';

export const Container = styled.div`
  padding: 3rem;

  & > * {
    padding: 0.5rem;
    max-width: 1440px;
    margin: auto;
  }

  @media screen and (max-width: 770px){
    padding: 1rem;

    & > * {
      padding: .5rem;
      margin: auto;
    }
  }
`;

export const VetContainer = styled.div`
  display: flex;
  
  flex-direction: column;

  @media screen and (min-width: 900px){
    border: 1px solid #1C1C1C;
    border-radius: 10px;
  }
`;

export const VetWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: no-wrap;
  margin-bottom: 2.5rem;

  @media screen and (max-width: 900px){
    flex-wrap: wrap;

  }

  @media screen and (max-width: 1200px){
    & > * {
      margin: 1rem auto;
    }
  }
`;

export const DetailsContainer = styled.div``;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TitleWrapper = styled.div`
  text-align: center;
  margin: auto;
`;

export const Slogan = styled.h2`
  font-family: ${({ theme }) => theme.fonts.subtitulo.font_family};
  font-weight: ${({ theme }) => theme.fonts.subtitulo.font_weight};
  font-size: 1.5rem;

  line-height: 2rem;
  color: ${({ theme }) => theme.colors.principal};

  @media screen and (max-width: 770px){
    font-size: 1rem;
  }
`;

export const ScheduleWrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
`;

export const LocalWrapper = styled.div`
`;
export const LocalTitleWrapper = styled.div`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.h1`
  font-family: ${({ theme }) => theme.fonts.titulo.font_family};
  font-weight: ${({ theme }) => theme.fonts.titulo.font_weight};
  font-size: 2.5rem;

  color: ${({ theme }) => theme.colors.principal};

  @media screen and (max-width: 770px){
    font-size: 1.5rem;
  }
`;

export const Image = styled.img`
  @media screen and (max-width: 900px){
    display: none;
  }
`;

export const VetImage = styled.img`
  max-width: 180px;
  max-height: 240px;

  @media screen and (max-width: 900px){
    max-width: 280px;
    margin-top: 10px;
  }

  @media screen and (max-width: 400px){
    max-width: 100%;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: start;

  margin-bottom: 1.5rem;
`;

export const Label = styled.label`
  font-family: ${({ theme }) => theme.fonts.subtitulo.font_family};
  font-weight: ${({ theme }) => theme.fonts.subtitulo.font_weight};
  font-size: 1.25rem;

  margin-right: .75rem;

`;

export const Highlight = styled.span`
text-decoration:underline;
color: #F28E1F;
font-size:1.5rem;
cursor: pointer; 
@media screen and (max-width: 770px){
  font-size: 1rem;
}
`
export const UnitVideo = styled.iframe`
    min-width: 45%;
    max-width: 750px;
    height: 400px;
    align-self: end;

    @media screen and (max-width: 770px){
        min-width: 95vw;

    }
`

export const Selector = styled.select`
  margin: 1rem 0rem;
  font-size: 1.2rem;
  padding: 0.5rem;
  outline-color: orange;
  border-radius: 5px;
  border-color: #F28E1F;
  background-color: #F5F5F5;
  
  &:focus {
    border-color: #1CAE70;
  }

  &:selected {
    border-color: red;
  }

  
`

export const SelectorOption = styled.option`

&:focus {
  border-color: #1CAE70;
}

&:checked {
  background-color: blue;
  color: white;
}

`

export const Text = styled.p`
  font-family: ${({ theme }) => theme.fonts.texto_corrido.font_family};
  font-weight: ${({ theme }) => theme.fonts.texto_corrido.font_weight};
  font-size: 1rem;

`;
export const List = styled.ul`
  list-style-type: disc;
  margin-left: 2.5rem;
  margin-bottom: 1.5rem;
`;
export const Li = styled.li`
  font-family: ${({ theme }) => theme.fonts.texto_corrido.font_family};
  font-weight: ${({ theme }) => theme.fonts.texto_corrido.font_weight};
  font-size: 1rem;

  margin: .5rem 0;

  @media screen and (max-width: 770px){
    font-size: .75rem;
  }
`;

export const Map = styled.iframe`
  display: block;
  margin-top: 1rem;
  width: 50%;
  align-self: center;
  justify-self: center;

  @media screen and (max-width: 770px){
    width: 100%;
  }
`;

export const Icon = styled.img`
  width: 16px;
`;

export const SocialMediaContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;

  @media screen and (max-width: 770px){
    margin-top: 1rem;
  }
`;

export const WppMediaIcon = styled(whatsappIconGreen)`
  cursor: pointer;
  @media screen and (max-width: 770px){
    width: 50px;
  }

  &:hover path {
    fill: orange;
  }

`;
export const InstaMediaIcon = styled(instagramIconGreen)`
  cursor: pointer;
  @media screen and (max-width: 770px){
    width: 50px;
  }

  &:hover path {
    fill: orange;
  }

`;


export const Link = styled.a`

&:hover path {
    fill: orange;
  }
`;

export const BottomSection = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 40px 0;
    column-gap: 20px;
    row-gap: 40px;
    font-family: 'Noto Sans', sans-serif;

`