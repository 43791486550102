import React, {useState} from "react";
import {TitleWrapper,
        Title,
        CardsWrapper,
        ToggleIcon,
        CliqueAbaixo
} from './styles';

import HealthPlanCard from "../HealthPlanCard/index.js";
import toggleIcon from "../../assets/images/triangle_white.svg"

function HealthPlanCategory(props){

    const [toggle, setToggle] = useState(0);

    function togglePlans(){
        setToggle(!toggle)
    }
    return(
        <div>
            <TitleWrapper onClick={togglePlans}>
                <Title>
                    {props.title}
                </Title>
                <ToggleIcon src={toggleIcon} toggle={toggle} />
            </TitleWrapper>
            <CardsWrapper type={props.type} size={props.plans.length} toggle={toggle}>
            {
                props.plans?.map((plan, i) => {
                    return(
                        <HealthPlanCard key={plan.id} isGreen={(i < props.plans.length / 2)} adds={props.adds} {...plan} />
                    )
                })
            }
            </CardsWrapper>
        </div>
    )
}

export default HealthPlanCategory;