import React from "react";
import { useRef, useCallback } from "react";
import { PopUpContainer, PersonalData, DatesData, DateInput, FormButton, GreyBackground } from "./styles";

import arrow from "../../assets/images/arrow_left.svg"

function SchedullingPopUp(props){

    
    function handleSubmit() {
        let data1 = new Date(data1Ref.current?.value).toLocaleDateString("pt-BR")
        let data2 = new Date(data2Ref.current?.value).toLocaleDateString("pt-BR")
        let data3 = new Date(data3Ref.current?.value).toLocaleDateString("pt-BR")
        
        window.open(`${props.tel}Me chamo ${nomeDonoRef.current?.value} e gostaria de solicitar o serviço ${servicoRef.current?.value} com o médico veterinário ${medicoRef.current?.value}
        %0A Minhas datas disponíveis são:%0A ${data1} no turno da ${turno1Ref.current?.value} %0A  ${data2} no turno da ${turno2Ref.current?.value} %0A  ${data3} no turno da 
        ${turno3Ref.current?.value}%0A. Meus dados de contato são:%0A Telefone - ${telRef.current?.value}%0A email - ${emailRef.current?.value}.`)
    }

    function SchedullingDate(props){
        return(
            <DateInput>
                <input required={props.isRequired} ref={props.ref_dia} type="date"  />
                <select ref={props.ref_turno} defaultValue={"Período"}>
                    <option value='manhã'>Manhã</option>
                    <option value='tarde'>Tarde</option>
                    <option value='noite'>Noite</option>
                </select>
            </DateInput>
        )
    }
    
    const nomeDonoRef = useRef()
    const nomePetRef = useRef()
    const telRef = useRef()
    const emailRef = useRef()
    const cidadeRef = useRef()
    const medicoRef = useRef()
    const servicoRef = useRef()

    const data1Ref = useRef()
    const data2Ref = useRef()
    const data3Ref = useRef()
    
    const turno1Ref = useRef()
    const turno2Ref = useRef()
    const turno3Ref = useRef()


    return(
        <>
            <GreyBackground className="form-background" isOpen={props.isOpen} onClick={(event) => event.target.classList.contains('form-background')? props.toggle() : ""}>
                <PopUpContainer isOpen={props.isOpen}>
                    <img src={arrow} alt={"Retornar"} onClick={props.toggle} />
                    <h1>Insira os dados para consulta</h1>
                    <form onSubmit={handleSubmit}>
                        <PersonalData> 
                            <h2>Dados Pessoais</h2>
                            <input required ref={nomeDonoRef} type="text" placeholder={'Nome do dono'}  />
                            <input required ref={nomePetRef} type="text" placeholder={'Nome do pet'}   />
                            <input required ref={telRef} type="tel" placeholder={'Telefone'}  />
                            <input required ref={emailRef} type="email" placeholder={'Email'}  />
                            <input required ref={cidadeRef} type="text" placeholder={'Cidade'}  />
                            <input required ref={medicoRef} type="text" placeholder={'Médico Veterinário'} value={props.name} />
                        </PersonalData>
                        <DatesData>
                            <h2>Escolha 3 datas</h2>
                            <SchedullingDate isRequired={true} ref_dia={data1Ref} ref_turno={turno1Ref}/>
                            <SchedullingDate isRequired={false} ref_dia={data2Ref} ref_turno={turno2Ref}/>
                            <SchedullingDate isRequired={false} ref_dia={data3Ref} ref_turno={turno3Ref}/>
                            <input required ref={servicoRef} type="text" placeholder="Tipo do serviço"  />
                        </DatesData>
                         <FormButton type="submit" value="Enviar"/>
                    </form>
                </PopUpContainer>
            </GreyBackground>
  
        </>
    )
}

export default SchedullingPopUp