import React from 'react';
import { Container, Text } from './styles';

function Button({ text, color, onclick }) {
  return (
    <Container color={color} onClick={onclick}>
      <Text>{text}</Text>
    </Container>
  );
}

export default Button;