import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.secundaria};

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: .25rem 1rem;
`;

export const Image = styled.img`
  @media screen and (max-width: 400px) {
    width: 150px;
  }
`;

export const MenuContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Wrapper = styled.div`
  @media screen and (max-width: 720px) {
    display: none;
  }
`;

export const Icon = styled.img`
  padding: 0 .5rem;
  cursor: pointer;
`;