import styled from "styled-components";

export const Wrapper = styled.div`
    background-color: #E6E6E6;

    display: flex;
    flex-direction: column;

    padding: 40px 100px;
    gap: 40px;

    h1{
        text-align: center;
        font-family: Raleway, sans-serif;
        font-weight: 700;
        font-size: 48px;

        @media screen and (max-width: 400px) {
            font-size: 36px;
        }
    }

    @media screen and (max-width: 834px){
        padding: 40px 60px;
    }

    @media screen and (max-width: 320px){
        padding: 40px 10px;
    }
`

export const Infos = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 40px;
    grid-row-gap: 40px;

    @media screen and (max-width: 680px){
        grid-template-columns: auto;
    }
`

export const Info = styled.div`
    font-size: 20px;
    font-family: 'Noto Sans', sans-serif;
    line-height: 27.24px;

    h2{
        font-weight: 700;
        display: inline;
    }
    p{
        display: inline;
    }
`