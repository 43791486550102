import styled from "styled-components";

import {ReactComponent as instagramIconGreen} from '../../assets/images/instagram_icon_green.svg';
import {ReactComponent as whatsappIconGreen} from '../../assets/images/whatsapp_icon_green.svg';

export const Container = styled.div`
    display: flex;
    margin: 40px 0;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 450px){
        padding: 0 10px; 
    }
`

export const UpperSection = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    

    margin: 40px 0;
    column-gap: 20px;
    row-gap: 40px;

    font-family: 'Noto Sans', sans-serif;

    h1{
        font-family: Raleway, sans-serif;
        color: #168C5A;
        font-size: 3rem;

        @media screen and (max-width: 320px){
            font-size: 2.25rem;
        }
    }

    p{
        color: #1C1C1C;
        font-size: 1.25rem;
        font-weight: 400;
        line-height: 110%;
    }
`

export const InfoSection = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    gap: 10px;
    max-width: 600px;

`
export const Wrapper = styled.div`
    margin-bottom: 1rem;
`

export const Bold = styled.span`
    font-weight: 600;
`

export const UnitTitle = styled.h1``

export const UnitSubtitle = styled.p`
    color: #168C5A !important;

    @media screen and (max-width: 320px) {
        display: none;
    }
`
export const UnitName = styled.p``

export const UnitServices = styled.p``

export const UnitHours = styled.p``

export const UnitPhones = styled.p``

export const UnitDescription = styled.p`
    font-size: 1.5rem;
    margin: 20px 0;
    text-align: justify;
`

export const LinksSection = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    width: 100%;
    gap: 45px;

    a{
        text-decoration: none;

        img{
            width: 60px;
            height: 60px;

            &:hover path {
                fill: orange;
              }
        }
    }
`

export const WppMediaIcon = styled(whatsappIconGreen)`
  cursor: pointer;
  @media screen and (max-width: 700px){
    width: 50px;
  }

  &:hover path {
    fill: orange;
  }

`;
export const InstaMediaIcon = styled(instagramIconGreen)`
  cursor: pointer;
  @media screen and (max-width: 700px){
    width: 50px;
  }

  &:hover path {
    fill: orange;
  }

`;

export const AddressSection = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    aling-itens: between;
    gap: 10px;
    max-width: 600px;
`

export const UnitAddress = styled.p`
    @media screen and (max-width: 320px){
        display: none;
    }
`

export const UnitMap = styled.iframe`
    background-color: grey;
    width: 100%;
    height: 400px;
`

export const BottomSection = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 40px 0;
    column-gap: 20px;
    row-gap: 40px;
    font-family: 'Noto Sans', sans-serif;

    h1{
        font-family: Raleway, sans-serif;
        color: #168C5A;
        font-size: 3rem;

        @media screen and (max-width: 320px){
            font-size: 2.25rem;
        }
    }

    p{
        color: #1C1C1C;
        font-size: 1.25rem;
        font-weight: 400;
        line-height: 110%;
    }

    @media screen and (max-width: 320px) {
        display: none;
    }
`
export const VideoWrapper = styled.div`
margin: 0 auto;
display: flex;
flex-direction: column;
justify-content: space-between;
`
export const UnitVideo = styled.iframe`
    width: 100%;
    min-width: 700px;
    max-width: 800px;
    height: 394px;

    @media screen and (max-width: 700px){
        min-width: 95vw;
    }
`

export const CarouselWrapper = styled.div`
    display: flex;
    margin: 0 auto;
    width: min(100%, 700px);
    justify-content: center;
    @media screen and (max-width: 320px){
        display: none;
    }
`

export const CarouselImg = styled.img`
    object-fit: fill;
    width: 10%;
`


export const MainImg = styled.img`
    width: 100%;
    min-height: 150px;
    object-fit: fill;
    display: none;

    @media screen and (max-width: 320px) {
        display: flex;
    }
`
