import styled from 'styled-components';

export const Container = styled.div`
  > * {
    padding: 2rem 15rem;
  }

  @media screen and (max-width: 1000px){
    > * {
      padding: 2rem 4rem;
    }
  }
`;

export const MainContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.secundaria};

  display: flex;
  justify-content: space-between;
  align-items: center;

  & > * {
    margin-top: 5rem;
  }

  @media screen and (max-width: 1400px) {
    flex-direction: column;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  max-width: 600px;
  min-width: 450px;

  @media screen and (max-width: 700px){
    width: 320px;
    min-width: auto;
    margin: 0;
  }
`;

export const Title = styled.h1`
  font-family: ${({ theme }) => theme.fonts.titulo.font_family};
  font-weight: ${({ theme }) => theme.fonts.titulo.font_weight};
  font-size: 2.5rem;

  color: ${({ color }) => color};

  margin-bottom: 3rem;

  @media screen and (max-width: 700px){
    font-size: 1.5rem;
  }
`;

export const Image = styled.img`
  @media screen and (max-width: 700px){
    display: none;
  }
`;

export const ResultsContainer = styled.div`
  @media screen and (max-width: 700px){
    padding: 1rem;
  }
`;

export const ResultsWrapper = styled.div`
  background-color: #E6E6E6;
  border-radius: 8px;

 
`;

export const VetDetail = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Text = styled.p`
  font-family: ${({ theme }) => theme.fonts.subtitulo.font_family};
  font-weight: ${({ theme }) => theme.fonts.subtitulo.font_weight};
  font-size: 1.25rem;

  padding: 1.25rem 1rem;

  @media screen and (max-width: 700px){
    font-size: .75rem;
  }
`;

export const Button = styled.button`
  background-color: ${({ theme }) => theme.colors.principal};
  
  font-family: ${({ theme }) => theme.fonts.texto_funcional.font_family};
  font-size: 1rem;

  color: ${({ theme }) => theme.colors.respiro};

  border: none;
  border-radius: 8px;

  padding: .2rem 1.25rem;
  margin: 0 .5rem;

  cursor: pointer;

  @media screen and (max-width: 700px){
    font-size: .75rem;
  }
`;