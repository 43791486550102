import styled from "styled-components";

export const CardInfoItemWrapper = styled.div`
    display: flex;
    gap: 8px;
    align-items: baseline;
`

export const CardInfoIcon = styled.img`
    width: 15px;
    height: 15px;
`

export const CardInfoItem = styled.div`
    display: flex;
    flex-direction: column;
`

export const CardInfoItemName = styled.p`
    font-size: 1.25rem;
`

export const CardInfoItemQuantity = styled.p`
    color: #3F3F3F;
`
export const Sup = styled.sup`
font-size: small;
vertical-align:super;

@media screen and (max-width: 824px){
  display: none;
}

`