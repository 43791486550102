import styled from 'styled-components';
import {ReactComponent as whatsapp_icon} from '../../assets/images/whatsapp_icon.svg'


export const Container = styled.div`
  padding: 4rem 3.5rem;
  background-color: #FAFAFA;
  margin: 0 5rem;

  @media screen and (max-width: 600px) {
    margin: 0;
  }
`;

export const Wrapper = styled.div`
  text-align: center;
`;

export const Title = styled.h2`
  font-size: 2rem;
  font-family: ${({ theme }) => theme.fonts.titulo.font_family};
  font-weight: ${({ theme }) => theme.fonts.titulo.font_weight};

  margin-bottom: 1rem;
`;

export const Subtitle = styled.p`
  font-size: 1.25rem;
  font-family: ${({ theme }) => theme.fonts.texto_funcional.font_family};
  font-weight: ${({ theme }) => theme.fonts.texto_funcional.font_weight};

  margin: 1rem 0rem;
`;

export const Icon = styled.img``;

export const FAQContainer = styled.div`
  margin: 2rem 0;
`;

export const WppIcon = styled(whatsapp_icon)`
  width: 60px;
  height: 60px;

  &:hover path {
    fill: orange;
  }
`
