import React from "react";
import { Container, CloseWrapper, CloseIcon, List, Item, Icon, BtnWrapper } from './styles';

import MenuArrow from '../../assets/images/menu_arrow.svg';
import CloseSvg from '../../assets/images/close_icon.svg';
import Button from "../Button";
import { Link } from "react-router-dom";

function Menu({ toggle }) {
  return (
    <Container>
      <CloseWrapper>
        <CloseIcon src={CloseSvg} onClick={() => { toggle(false) }} />
      </CloseWrapper>
      <List>
       <Link to='/' onClick={() => { toggle(false) }}> 
         <Item>
            Início
         </Item> 
       </Link> 

       <Link to='/planos' onClick={() => { toggle(false) }}> 
          <Item>
            Planos
          </Item>
        </Link> 

        <Link to='/medicos-veterinarios' onClick={() => { toggle(false) }}> 
          <Item>
            Veterinários
          </Item>
        </Link>

        <Link to='/unidades' onClick={() => { toggle(false) }}> 
          <Item>
            Unidades
          </Item>
        </Link>


        
        <Link to='/parceiros' onClick={() => { toggle(false) }}>
          <Item>
            Parceiros
          </Item>
        </Link>

      </List>
      <BtnWrapper>
        <Link to='/consulta-online' onClick={() => { toggle(false) }}> 
          <Button text="Agendar Consulta" color="orange" />
        </Link>
        <a href="https://www.redevidaanimal.com/" >
        <Button text="Espaço do cliente" color="green" />
        </a>
          
      </BtnWrapper>
    </Container>
  )
}

export default Menu;